"use strict";

angular.module('fiskal').controller('gridController',
  ['ffNavbarService', 'gridConfigService', 'gridService', 'gridDataService',
    'uiGridConstants', '$uiRouter', '$scope', '$q', '$log',
    function (ffNavbarService, gridConfigService, gridService, gridDataService,
              uiGridConstants, $uiRouter, $scope, $q, $log) {

      var self = this;
      $log.debug("Entered gridController at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));

      var deregs = []; // eventhandlers to deregister

      var gconf = gridConfigService;

      var deferedGridApi = $q.defer();
      var gridApiPromise = deferedGridApi.promise;

      self.gridOptions = gconf.getGridOptions();
      self.gridOptions.onRegisterApi = onRegisterApi;

      //ensure that no data is shown at controller initialization time
      self.gridOptions.data = [];

      deregs.push(watchParams());

      deregs.push($scope.$watch(gridService.getShowDetail, function (newVal, oldVal) {
        gridApiPromise.then(function () {
          // $log.debug("Show details changed - gridApi ready: " + oldVal + " --> " + newVal);
          var detailCols = gconf.getPresetCols('detail', self.params.datasource);
          detailCols.forEach(function (c) {
            gconf.setColProperty(self.params.datasource, c.name, 'visible', gridService.showDetail);
          });
          self.gridOptions.columnDefs = gconf.getColumnDefs(self.params.datasource);
          self.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        });
      }));

      //clean up event handlers
      $scope.$on('$destroy', function () {
        deregs.forEach(function (dereg) {
          dereg();
        });
      });

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function watchParams() {

        return $scope.$watch(getParams, updateParams);

        function getParams() {
          return $uiRouter.globals ? $uiRouter.globals.params : null;
        }

        function updateParams(newVal, oldVal) {
          //we RELY on the initialization invocation so no old == new check
          var params = getParams();
          $log.debug("updateParams...GLO : grid: " + angular.toJson(params));
          if (!params) {
            return;
          }
          self.params = angular.copy(params);
          if (self.params.hasOwnProperty('#')) {
            delete self.params['#'];
          }
          //only update if a minimal set of params has been defined
          if (self.params && self.params.datasource && self.params.showresult) {
            $log.debug("state/dataSrc: " + self.params.datasource);
            //clear data
            self.gridOptions.data = [];
            //load column definitions
            self.gridOptions.columnDefs = gconf.getColumnDefs(self.params.datasource);
            //wait for the gridapi to be ready
            $q.all([gridApiPromise]).then(function () {

              // links from grid cells: expose on $scope required for grid templates
              $scope.cellLink = gridService.getAction(self.params.datasource, self.gridApi);

              // handles for modification markings and "saving data" animations
              $scope.isModified = gridService.getIsModified(self.params.datasource);
              $scope.isSaving = gridService.getIsSaving(self.params.datasource);


              // map for subgrids
              $scope.getSubgridOptions = gconf.getSubgridOptionsDefinition(self.params.datasource);
              //event handlers
              // deregs.push(self.gridApi.core.on.filterChanged($scope, queryFilterChanged));
            });
            //build query and load data
            var query = gconf.getQuery(self.params);
            gridService.setSearching(true);
            gridDataService.queryData(query, self.gridOptions, self.params.datasource).then(
              function success(resp) {
                // remove searching flag and stop search animation
                gridService.setSearching(false);
              },
              function error(resp) {
                // remove searching flag and stop search animation.
                // TODO complain, evt. skelne mellemnetværksfejl og fejlkode  smk
                gridService.setSearching(false);
              }
            );
          }
        }
      }

      function onRegisterApi(gridApi) {
        this.api = gridApi;
        onRegisterApi.api = gridApi;
        self.gridApi = gridApi;
        deferedGridApi.resolve();
      }

    }
  ]);
