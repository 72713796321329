'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridService',
  ['GridRow', '$translate', '$filter', '$locale', '$injector', '$log',
    function (GridRow, $translate, $filter, $locale, $injector, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables
      var _tooltipFunctions = defineTooltipFunctions();
      var _userData = null;
      var _userDataValues = null;
      var _dropdownTranlatings = {};
      var _searching = false;

      var _savingCells = {};

      // api: public methods
      self.toDateStr = toDateStr;
      self.toDateTs = toDateTs;
      self.toIsoTimestamp = toIsoTimestamp;
      self.parseDateLike = parseDateLike;

      self.getGridDisplayedValue = getGridDisplayedValue;
      self.formatAsDisplayValue = formatAsDisplayValue;
      self.parseGridDisplayedValue = parseGridDisplayedValue;

      self.showDetail = false;
      self.getShowDetail = getShowDetail;

      self.toggleSearching = toggleSearching;
      self.getSearching = getSearching;
      self.setSearching = setSearching;


      self.getUserData = getUserData;
      self.setUserData = setUserData;

      self.getUserDataValues = getUserDataValues;

      self.translate = translate;
      self.getDropdownValue = getDropdownValue;

      self.getTooltipFunctions = getTooltipFunctions;
      self.getAction = getAction;

      self.getIsModified = getIsModified;
      self.getIsSaving = getIsSaving;
      self.setSaving = setSaving;


      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods
      function getShowDetail() {
        return self.showDetail;
      }

      function toggleSearching(searching) {
        var sch = angular.isDefined(searching) ? searching : _searching;
        _searching = !sch;
        return _searching;
      }

      function getSearching() {
        return _searching;
      }

      function setSearching(searching) {
        _searching = searching;
        return _searching;
      }

      function getUserData() {
        return _userData;
      }

      function setUserData(userData) {
        _userData = userData;
        if (_userData) {
          _userData.sort(userDataSort);

          _userDataValues = _userData.reduce(function (acc, el, idx) {
            Object.keys(el).forEach(function (key) {
              //use acc.unq as uniqueness hash
              if (!angular.isDefined(acc.unq[key])) {
                acc.unq[key] = {};
              }
              //push first value found i.e. keep sort order
              if (!angular.isDefined(acc.unq[key][el[key]])) {
                acc.unq[key][el[key]] = idx;
                if (!angular.isDefined(acc[key])) {
                  acc[key] = [];
                }
                acc[key].push(el[key]);
              }
            });
            return acc;
          }, {unq: {}});
          delete _userDataValues.unq; // unq not needed outside reduce
          // Sort userDataValues so that regnr and vognnr dropdown lists are sorted.
          Object.keys(_userDataValues).forEach(function (key) {
            _userDataValues[key].sort(userDataValueSort);
          });
        } else {
          _userDataValues = null;
        }

        return _userDataValues;

        function userDataValueSort(a, b) {
          var res = 0;
          if (typeof a == 'number' && typeof b == 'number' && isFinite(a) && isFinite(b)) {
              res = a - b;
          } else {
            res = String(a).localeCompare(String(b));
          }
          return res;
        }

        function userDataSort(a, b) {
          var res = sortWithNull(a.vognnr, b.vognnr);
          return res;

          function sortWithNull(a, b) {
            var res = 0;
            //sort invalid last
            if (isValidNumber(a)) {
              res = isValidNumber(b) ? a - b : -1;
            } else {
              res = isValidNumber(b) ? 1 : 0;
            }
            return res;
          }

          function isValidNumber(num) {
            return angular.isDefined(num) && isFinite(num);
          }
        }
      }

      function getUserDataValues(colname) {
        if (colname) {
          return _userDataValues[colname];
        } else {
          return _userDataValues;
        }
      }

      function getDropdownValue(name, dropdown, raw) {
        dropdown.translatedrange = dropdown.translatedrange || [];
        if (!dropdown.translatedrange[raw]) {
          dropdown.translatedrange[raw] = {istranslated: false};
        }
        if (!dropdown.translatedrange[raw].istranslated) {
          translate(name, dropdown);
        }
        return dropdown.translatedrange[raw].value;
      }

      function translate(name, dropdown) {

        if (_dropdownTranlatings[name]) {
          return;
        } else {
          _dropdownTranlatings[name] = true;
        }

        var range = dropdown.translatedrange;

        var raw = Object.keys(range).reduce(function (acc, key) {
          if (!range[key]) {
            $log.error(key + " is not defined in range for translate");
          }
          if (!range[key].istranslated) {
            acc.push(key);
          }
          return acc;
        }, []);

        if (raw.length < 1) {
          return;
        }

        var transKeys = raw.map(function (el) {
          return dropdown.basekey + el;
        });

        $translate(transKeys).then(function (translated) {
          raw.forEach(function (r, idx) {
            if (transKeys[idx] === translated[transKeys[idx]]) {
              range[r].value = raw[idx];
            } else {
              range[r].value = translated[transKeys[idx]];
            }
            range[r].istranslated = true;
          });
          _dropdownTranlatings[name] = null;
        });

      }

      function getTooltipFunctions() {
        return _tooltipFunctions;
      }

      function defineTooltipFunctions() {
        function empty() {
          return "";
        }

        function showValue(row, col) {
          return getGridDisplayedValue(row, col);
        }

        function _showText(tranlateSymbol, translateProperties) {
          return function showText(row, col) {
            var props = typeof translateProperties === 'function' ? translateProperties() : translateProperties;
            var symb = tranlateSymbol === 'function' ? tranlateSymbol() : tranlateSymbol;
            return $translate.instant(symb, props);
          };
        }

        function _showTextWValues(tranlateSymbol, props) {
          var _props = props instanceof Array ? props : [props];
          return function showTextWValues(row, col) {
            var isValid = !!row && !!col;
            isValid = isValid && !!row.entity && !!col.field && !!row.grid;
            isValid = isValid && angular.isDefined(row.entity[col.field]) && row.entity[col.field] !== null && row.entity[col.field] !== "";
            if (!isValid) {
              return "";
            }
            var transOptions = _props.reduce(function (acc, p) {
              acc[p] = showValue(row, row.grid.getColDef(p));
              return acc;
            }, {});
            return _showText(tranlateSymbol, transOptions)(row, col);
          };
        }

        //noinspection UnnecessaryLocalVariableJS
        var tooltipFunctions = {
          empty: empty,
          showValue: showValue,
          showText: _showText,
          showTextWValues: _showTextWValues
        };
        return tooltipFunctions;
      }

      function getIsModified(dataSrc) {
        return function isModified(row, col, $event) {
          if (row && row.entity) {
            if (row.entity.turnr && row.entity.turnr.trim().search(/^\d+[a-zA-Z]+$/) > -1) {
              //userrow
              return !getSaving(dataSrc, row, col);
            } else if (col && ["momsprocent", "indkoertTab"].includes(col.name) && row.entity.modChangelog && row.entity.modChangelog.length > 0) {
              //other modifications
              var mod = row.entity.modChangelog.reduce(function (acc, r) {
                acc = acc || Object.keys(r).includes(col.name);
                return acc;
              }, false);
              return mod && !getSaving(dataSrc, row, col);
            } else if (typeof row.entity.antalTure === 'number' && row.entity.modified === true) {
              // modified vagt - vi identificerer en vagt på at den har en antalTure kolonne
              return true;
            }
          }
          return false;
        };
      }

      function setSaving(dataSrc, row, col, value) {
        if (!dataSrc || !row || !row.entity || !row.entity.$$hashKey || !col || !col.field) {
          return false;
        }
        //sparse, lazy "array"
        if (value) {
          //use memory for truth
          _savingCells[dataSrc] = _savingCells[dataSrc] || {};
          _savingCells[dataSrc][row.entity.$$hashKey] = _savingCells[dataSrc][row.entity.$$hashKey] || {};
          _savingCells[dataSrc][row.entity.$$hashKey][col.field] = !!value;
        } else {
          //dont store false - and clean up => keep memory consumption down
          if (angular.isDefined(_savingCells[dataSrc])
            && angular.isDefined(_savingCells[dataSrc][row.entity.$$hashKey])
            && angular.isDefined(_savingCells[dataSrc][row.entity.$$hashKey][col.field])) {
            delete _savingCells[dataSrc][row.entity.$$hashKey][col.field];
            if (Object.keys(_savingCells[dataSrc][row.entity.$$hashKey]).length === 0) {
              delete _savingCells[dataSrc][row.entity.$$hashKey];
              if (Object.keys(_savingCells[dataSrc]).length === 0) {
                delete _savingCells[dataSrc];
              }
            }
          }
        }
        return !!_savingCells[dataSrc] && !!_savingCells[dataSrc][row.entity.$$hashKey] && !!_savingCells[dataSrc][row.entity.$$hashKey][col.field];
      }

      function getSaving(dataSrc, row, col) {
        if (!dataSrc || !row || !row.entity || !row.entity.$$hashKey || !col || !col.field) {
          return false;
        }
        return !!_savingCells[dataSrc] && !!_savingCells[dataSrc][row.entity.$$hashKey] && !!_savingCells[dataSrc][row.entity.$$hashKey][col.field];
      }

      function getIsSaving(dataSrc) {
        return function (row, col, $event) {
          return getSaving(dataSrc, row, col);
        };
      }


      function getAction(dataSrc, gridApi) {

        var specials = ['datasource'];
        var $uiRouter = $injector.get("$uiRouter");
        var gridConfigService = $injector.get("gridConfigService");
        var cellActionService = $injector.get("cellActionService");

        return doAction;

        function doAction(row, col, evt) {
          evt.preventDefault(); // the action stops here

          if (cellActionService.handle(dataSrc, row, col, evt)) {
            return; // special case handled by "cellaction"
          }

          var params = {};
          var colLinks = gridConfigService.getColumnLinks(dataSrc, col.name);
          if (colLinks) {
            var linkParams = colLinks.params;
            Object.keys(linkParams).forEach(function (key) {
              if (specials.includes(key)) {
                if (key === "datasource") {
                  switch (dataSrc) {
                    case "vagt":
                      params[key] = "tur";
                      break;
                    case "tur":
                      params[key] = "vagt";
                      break;
                  }
                }
              } else if (linkParams[key] instanceof Array) {
                params[key] = linkParams[key].map(function (subKey) {
                  return getGridDisplayedValue(row, row.grid.getColDef(subKey));
                });
              } else if (key.match(/^s(?:(?:tar)|(?:lu))tTs$/)) { // startTs|slutTs
                params[key] = getGridDisplayedValue(row, row.grid.getColDef(key));
                // DATETIME FORMAT DEPENDENCY
                params[key] = params[key] ? moment.tz(params[key], ['DD.MM.YYYY HH:mm'], 'Europe/Berlin').format('DD.MM.YYYY') : params[key];
              }
              else {
                params[key] = getGridDisplayedValue(row, row.grid.getColDef(key));
              }
            });
            params["showresult"] = true;
            $log.debug('colLinks: params: ' + angular.toJson(params));
            $uiRouter.stateService.go(colLinks.state, params);
          }
        }

      }


      function parseDateLike(d) {
        if (d) {
          if (d instanceof Date) {
            d = d.valueOf();
          }
          // DATETIME FORMAT DEPENDENCY
          d = moment.tz(d, ['DD.MM.YYYY HH:mm'], 'Europe/Berlin').toDate();
        }
        return d;
      }

      function toDateStr(p, offset, scale) {
        var o = offset || 0;
        var s = scale || 'days';
        var d = p;
        if (d instanceof Date) {
          d = d.valueOf();
        }
        return parsableDateStr(d, o, s);
      }

      function toIsoTimestamp(parsableDate, offset, scale) {
        var _offset = offset || 0;
        var _scale = scale || 'days';
        // DATETIME FORMAT DEPENDENCY
        return moment.tz(parsableDate, ['DD.MM.YYYY'], 'Europe/Berlin').add(_offset, _scale).format();
      }


      function parsableDateStr(d, offset, scale) {
        // DATETIME FORMAT DEPENDENCY
        return moment.tz(d, 'Europe/Berlin').add(offset, scale).format("DD.MM.YYYY");
      }

      function toDateTs(p, offset, scale) {
        var o = offset || 0;
        var s = scale || 'days';
        var d = p;
        if (d instanceof Date) {
          d = d.valueOf();
        }
        return parsableDateTs(d, o, s);
      }

      function parsableDateTs(d, offset, scale) {
        return moment.tz(d, 'Europe/Berlin').add(offset, scale).valueOf();
      }


      function getGridDisplayedValue(rowEntity, colDef) {
        if (!rowEntity || !colDef) {
          return "";
        }
        var row = rowEntity;
        if (row instanceof GridRow) {
          row = row.entity;
        }
        var displayValue = row[colDef.field];

        if (colDef.cellFilter) {
          displayValue = formatAsDisplayValue(row[colDef.field], colDef);
        }
        return displayValue;
      }

      function formatAsDisplayValue(value, colDef) {
        var val = null;
        if (!colDef) {
          return "";
        }
        if (colDef.cellFilter) {
          var parsedFilter = parseCellFilter(colDef.cellFilter);
          val = $filter(parsedFilter[0])(value,
            parsedFilter.length > 1 ? parsedFilter[1] : null,
            parsedFilter.length > 2 ? parsedFilter[2] : null,
            parsedFilter.length > 3 ? parsedFilter[3] : null,
            parsedFilter.length > 4 ? parsedFilter[4] : null
          );
        } else {
          val = value;
        }
        return val !== null ? val : "";
      }

      function parseGridDisplayedValue(value, colDef) {

        if (value && colDef.cellFilter) {
          var save = value;
          var cellFilter = parseCellFilter(colDef.cellFilter);

          var dsep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
          var gsep = $locale.NUMBER_FORMATS.GROUP_SEP;
          var numberRegex = new RegExp("[^0-9" + gsep + dsep + "]", "g");

          try {
            var scale = 1.0;
            //noinspection FallThroughInSwitchStatementJS
            switch (cellFilter[0]) {
              case "currency":
                value = value.replace(numberRegex, "");
              // eslint-disable-line no-fallthrough
              case "scaledNumber":
                scale = cellFilter[2] || scale;
              // eslint-disable-line no-fallthrough
              case "number":
                value = scaleValue(value, scale).toFixed();
                break;
              case "date":
                // DATETIME FORMAT DEPENDENCY
                value = moment.tz(value, cellFilter[1].replace(/dd(.MM.)yyyy/g, "DD$1YYYY"), 'Europe/Berlin').format();
                break;
              case "duration":
                // DATETIME FORMAT DEPENDENCY
                value = moment.duration(moment.tz(value, 'Europe/Berlin').diff(moment.tz('Europe/Berlin').startOf('day'))) / (cellFilter[2] ? cellFilter[2] : 1);
                break;
            }
          } catch (e) {
            value = save;
          }
        }
        return value;

        function scaleValue(val, scl) {
          val = val.replace(new RegExp("[" + gsep + "]", "g"), "");
          val = val.replace(new RegExp("[" + dsep + "]", "g"), ".");
          if (val.includes(".")) {
            val = parseFloat(val);
            val = val / scl;
          } else {
            val = parseInt(val);
            val = Math.round(val / scl);
          }
          return val;
        }
      }

      // Split string med ':' og unquote quotede substrings:
      // Fx filter = "date: 'dd.MM.yyyy HH.mm'" giver et array ['date', 'dd.MM.yyyy HH.mm']
      // Fx filter = "scaledNumber: '2' : '0.001'" giver et array ['scaledNumber','2', '0.001']
      // DATETIME FORMAT DEPENDENCY - hvis der vælges et meget sygt datetimeformat
      function parseCellFilter(filter) {
        if (!filter) {
          return filter;
        }
        var parsedFilter = filter;
        var saves = [];
        // protect things inside "" or '' e.g. ":"
        parsedFilter = parsedFilter.replace(/['"]([^'"]*?)['"]/g, function (match, p1) {
          var i = saves.length;
          saves[i] = p1;
          return "TOKEN_" + i;
        });
        var tokens = parsedFilter.split(":");
        tokens = tokens.map(function (t) {
          var x = t.replace(/TOKEN_(\d+)/g, function (match, p1) {
            return saves[p1];
          });
          return x ? x.trim() : x;
        });
        return tokens;
      }

    }
  ]);
