'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridConfigDefaultsService',
  ['gridService', 'gridCellTemplateService', 'uiGridConstants', '$log',
    function (gridService, gridCellTemplateService, uiGridConstants, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables
      var _gridOptions = defineDefaultGridOptions();
      var _columnDefaults = defineDefaultColumnDefaults();
      var _columnPresets = defineDefaultColumnPresets();
      var _cellTooltips = defineDefaultCellTooltips();
      var _headerTooltips = defineDefaultHeaderTooltips();
      var _columnDefs = defineDefaultColumnDefs();
      var _columnLinks = defineDefaultColLinks();
      var _subgridOptions = defineDefaultSubgridOptions();
      var _subgridColumnDefs = defineDefaultSubgridColumnDefs();

      // api: public methods and properties
      self.getGridOptions = getGridOptions;
      self.getColumnDefaults = getColumnDefaults;
      self.getColumnPresets = getColumnPresets;
      self.getCellTooltips = getCellTooltips;
      self.getHeaderTooltips = getHeaderTooltips;
      self.getColumnDefs = getColumnDefs;
      self.getColumnLinks = getColumnLinks;
      self.getSubgridOptions = getSubgridOptions;
      self.getSubgridColumnDefs = getSubgridColumnDefs;

      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function getGridOptions() {
        return  angular.copy(_gridOptions);
      }

      function getColumnDefaults() {
        return  _columnDefaults;
      }

      function getColumnPresets() {
        return  _columnPresets;
      }

      function getCellTooltips() {
        return  _cellTooltips;
      }

      function getHeaderTooltips() {
        return  _headerTooltips;
      }

      function getColumnDefs() {
        return  _columnDefs;
      }

      function getColumnLinks() {
        return  _columnLinks;
      }

      function getSubgridOptions() {
        return angular.copy(_subgridOptions);
      }

      function getSubgridColumnDefs() {
        return  _subgridColumnDefs;
      }

      //CONFIGS

      function defineDefaultGridOptions() {
        var gridoptions = {
          minimumColumnSize: 100,
          excessColumns: 10,
          excessRows: 6,
          virtualizationThreshold: 10,
          enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
          enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
          enableRowSelection: false,
          enableFullRowSelection: false,
          enableColumnMenus: false,
          enableSorting: false,
          enableFiltering: false,
          flatEntityAccess: false,
          fastWatch: true,
          showColumnFooter: true
        };
        return gridoptions;
      }

      function defineDefaultColumnDefaults() {
        var columnDefaults = {
          headerCellFilter: 'translate',
          cellClass: 'ff-grid-left',
          type: 'string',
          enableColumnMenu: false,
          enableSorting: false,
          minWidth: 85,
          maxWidth: 100,
          visible: true
        };
        return columnDefaults;
      }

      function defineDefaultColumnPresets() {
        var columnPresets = {
          integer: {
            cellClass: 'ff-grid-right',
            cellFilter: "number: '0'",
            type: 'number'
          },
          km: {
            cellClass: 'ff-grid-right',
            cellFilter: "scaledNumber: '2' : '0.001'",
            type: 'number'
          },
          kr: {
            cellClass: 'ff-grid-right',
            cellFilter: "scaledNumber: '2' : '0.01'",
            type: 'number'
          },
          momsprocent: {
            cellClass: 'ff-grid-right',
            cellFilter: "scaledNumber: '2' : '0.001'",
            type: 'number'
          },
          datetime: {
            cellClass: 'ff-grid-right',
            // DATETIME FORMAT DEPENDENCY
            cellFilter: "date: 'dd.MM.yyyy HH:mm'",
            type: 'date'
          },
          datetimesec: {
            cellClass: 'ff-grid-right',
            // DATETIME FORMAT DEPENDENCY
            cellFilter: "date: 'dd.MM.yyyy HH:mm.ss'",
            type: 'date'
          },
          duration: {
            cellClass: 'ff-grid-right',
            // DATETIME FORMAT DEPENDENCY
            cellFilter: "duration: 'HH:mm' : '1000'",
            type: 'text'
          },
          time: {
            cellClass: 'ff-grid-right',
            // DATETIME FORMAT DEPENDENCY
            cellFilter: "date: 'HH:mm'",
            type: 'date'
          },
          aggInt: {
            aggregationType: uiGridConstants.aggregationTypes.sum,
            footerCellClass: 'ff-grid-right',
            footerCellFilter: "number: '0'",
            aggregationHideLabel: true
          },
          aggDuration: {
            aggregationType: uiGridConstants.aggregationTypes.sum,
            footerCellClass: 'ff-grid-right',
            // TIME FORMAT DEPENDENCY
            footerCellFilter: "duration: 'HH:mm' : '1000'",
            aggregationHideLabel: true
          },
          aggKm: {
            aggregationType: uiGridConstants.aggregationTypes.sum,
            footerCellClass: 'ff-grid-right',
            footerCellFilter: "scaledNumber: '2' : '0.001'",
            aggregationHideLabel: true
          },
          aggKr: {
            aggregationType: uiGridConstants.aggregationTypes.sum,
            footerCellClass: 'ff-grid-right',
            footerCellFilter: "scaledNumber: '2' : '0.01'",
            aggregationHideLabel: true
          },
          left: {
            cellClass: 'ff-grid-left'
          },
          center: {
            cellClass: 'ff-grid-center'
          },
          right: {
            cellClass: 'ff-grid-right'
          },
          detail: {
            visible: gridService.showDetail
          },
          spacer: {
            field: "empty_placeholder",
            name: "empty_placeholder",
            displayName: "",
            minWidth: 40,
            maxWidth: 40,
            width: 40
          }

        };
        return columnPresets;
      }

      function defineDefaultCellTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var cellTooltips = {
          "*": ttf.empty
        };
        return cellTooltips;
      }

      function defineDefaultHeaderTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var headerTooltips = {
          "*": ttf.empty
        };
        return headerTooltips;
      }

      function defineDefaultColumnDefs() {
        var columnDefs = [];
        return columnDefs;
      }

      function defineDefaultColLinks() {
        var columnLinks = {};
        return columnLinks;
      }

      function defineDefaultSubgridOptions() {
        var subgridOptions = {
          flatEntityAccess: false,
          fastWatch: false,
          showColumnFooter: false,
          minRowsToShow: 3,
          rowHeight: 40,
          enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
          enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS
        };
        return subgridOptions;
      }

      function defineDefaultSubgridColumnDefs() {
        var subgridColumnDefs = {};
        return subgridColumnDefs;
      }

    }
  ]);
