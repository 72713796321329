'use strict';

angular.module('fiskal').service('gridDataService',
  ['installationvariants', "gridService", 'gridConfigService', 'GridRow', 'ffNotifyService', '$locale', '$filter', '$http', '$q', '$log',
    function (installationvariants, gridService, gridConfigService, GridRow, ffNotifyService, $locale, $filter, $http, $q, $log) {

      var self = this; // return self

      //closure vars
      var dataUrls = {
        'user': installationvariants.resourcePrefix + "user/search",
        'vagt': installationvariants.resourcePrefix + "vagt/search",
        'tur': installationvariants.resourcePrefix + "turModified/search",
        'userMod': installationvariants.resourcePrefix + "userModification",
        'userModDo': installationvariants.resourcePrefix + "userModification/doProcedure"

      };

      var exportUrls = {
        'vagt': {
          url: installationvariants.resourcePrefix + "vagt/export",
          exportName: 'Schicht'
        },
        'tur': {
          url: installationvariants.resourcePrefix + "turExport/export",
          exportName: 'Tour'
        }
      };

      //init

      //api
      self.queryData = queryData;
      self.exportData = exportData;
      self.saveTurRow = saveTurRow;
      self.deleteTurRow = deleteTurRow;
      self.getNextTurnr = getNextTurnr;

      function saveTurRow(row) {

        var method, url;
        if (row.modId && (row.modType === "modified") || (row.turId < 0 && row.modType === "created") ){
          method = 'PATCH';
          url = dataUrls['userMod'] + "/" + row.modId;
        } else {
          method = 'POST';
          url = dataUrls['userMod'];
        }
        delete row.modId;


        return $http({method: method, url: url, data: row}).then(
          function success(response) {
            $log.info("SUCCESS loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText + " location: " + response.headers("Location"));
            var rr = parseRestRows('userMod', response.data);
            var turId = rr[0].turId;
            return $http({
              method: "POST",
              url: dataUrls['tur'],
              data: {search: [{field:"turId", op: "eq", args: [turId]}]}
            }).then(
              function success(response) {
                $log.info("SUCCESS loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText + " location: " + response.headers("Location"));
                var rr = parseRestRows('tur', response.data);
                return rr[0];
              },
              function error(response) {
                $log.error("ERROR loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText);
                return $q.reject();
              });
          },
          function error(response) {
            $log.error("ERROR loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText);
            return $q.reject();
          }
        );

      }

      function deleteTurRow(row) {

        return $http.delete(dataUrls['userMod'] + "/" + row.modId).then(
          function success(response) {
            $log.info("SUCCESS deleting '" + response.config.url + "' data: " + angular.toJson(response.data) + " -- status: " + response.status + ": " + response.statusText);
            return $q.resolve();
          },
          function error(response) {
            $log.error("ERROR deleting '" + response.config.url + "' data: " + angular.toJson(response.data) + " -- status: " + response.status + ": " + response.statusText);
            return $q.reject();
          }
        );
      }


      function getNextTurnr(turId) {

        return $http.post(dataUrls["userModDo"], {do: "nextTurnr", data: {parentTurId: turId}}).then(
          function success(response) {
            var dataLoaded = "SUCCESS loading '" + response.config.url + "' data: " + angular.toJson(response.data) + " status: " + response.status + ": " + response.statusText;
            $log.info(dataLoaded);
            ffNotifyService.notify(dataLoaded);
            return response.data.nextTurnr;
          },
          function error(response) {
            $log.error("ERROR loading '" + response.config.url + "' data: " + angular.toJson(response.data) + " -- status: " + response.status + ": " + response.statusText);
          }
        );
      }


      function queryData(query, gridOptions, dataSrc) {

        return $http.post(dataUrls[dataSrc], query).then(
          function success(response) {
            var dataLoaded = "SUCCESS loading '" + response.config.url + "' status: " + response.status + ": " + response.data.length + " data rows";
            $log.info(dataLoaded);
            ffNotifyService.notify(dataLoaded);
            return gridOptions.data = parseRestRows(dataSrc, response.data);
          },
          function error(response) {
            $log.error("ERROR loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText);
            $q.reject();
          }
        );
      }

      function exportData(query, dataSrc) {
        /*global saveAs*/
        return $http.post(exportUrls[dataSrc].url, query).then(
          function success(response) {
            var dataLoaded = "SUCCESS loading '" + response.config.url + "' status: " + response.status + ": " + response.data.length + " characters";
            $log.info(dataLoaded);
            ffNotifyService.notify(dataLoaded);
            var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
            return saveAs(blob, exportUrls[dataSrc].exportName + ".csv");
          },
          function error(response) {
            $log.error("ERROR loading '" + response.config.url + "' data: " + response.status + ": " + response.statusText);
          }
        );
      }


      function parseRestRows(src, rows) {
        if (!(rows instanceof Array)) {
          rows = [rows];
        }

        var dateFields = gridConfigService.getDateCols(src).map(function (c) {
          return c.name;
        });

        var coldef = gridConfigService.getColumnDefs(src);
        if (coldef) {
          coldef = coldef.reduce(function (acc, cdef) {
            acc[cdef.name] = cdef;
            return acc;
          }, {});
        }

        rows.forEach(function (row) {
          //format changelog
          if (src === 'tur' && row.modChangelog) {
            row.modChangelog = angular.fromJson(row.modChangelog).map(function (ll) {
              var formattedChangeLog = Object.keys(ll).reduce(function (acc, k) {
                switch (k) {
                  case "modCreatedAt":
                    acc[k] = gridService.formatAsDisplayValue(strToDate(ll[k]), coldef.modCreatedAt);
                    break;
                  case "fields":
                    Object.keys(ll.fields).forEach(function (kk) {
                      var fld = ll.fields[kk];
                      if (dateFields.includes(kk)) {
                        fld.old = strToDate(fld.old);
                        fld.new = strToDate(fld.new);
                      }
                      acc[kk] = gridService.formatAsDisplayValue(fld.old, coldef[kk]) + " -> " + gridService.formatAsDisplayValue(fld.new, coldef[kk]);
                    });
                    break;
                  default:
                    acc[k] = ll[k];
                    break;
                }
                return acc;
              }, {});
              return angular.merge(ll, formattedChangeLog);
            });
          }
          //parse dates and times
          dateFields.forEach(function (c) {
            var val = row[c];
            row[c] = strToDate(val);
          });
        });

        return rows;

        function strToDate(val) {
          return val ? moment.tz(val, 'Europe/Berlin').toDate() : val;
        }

      }

      return self;
    }])
;
