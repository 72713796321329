'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridSearchConfigService',
  ['$log',
    function ($log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables
      var _searchFields = {
        vagt: {init: defineVagtSearchFields, data: defineVagtSearchFields()},
        tur: {init: defineTurSearchFields, data: defineTurSearchFields()}
      };
      // api: public methods and properties
      self.getSearchFields = getSearchFields;
      self.resetSearchFields = resetSearchFields;

      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function getSearchFields(state) {
        return  _searchFields[state].data;
      }
      function resetSearchFields(state) {
        _searchFields[state].data = _searchFields[state].init();
        return  _searchFields[state].data;
      }

      //CONFIGS

      function defineVagtSearchFields() {
        var searchFields = {
/*
          datasource: {
            type: "radio",
            radio: [{label: "grid.search.label.vagt", value: "vagt"}, {label: "grid.search.label.tur", value: "tur"}],
            label: "grid.search.label.datakilde",
            placeholder: ""
          },
          selskabsnr: {
            type: "text",
            label: "grid.search.label.selskabsnr",
            placeholder: "grid.search.placeholder.selskabsnr",
            dropdown: {
              basekey: "grid.search.selskabsnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
 */
          vognnr: {
            type: "text",
            label: "grid.search.label.vognnr",
            placeholder: "grid.search.placeholder.vognnr",
            width: "15ex",
            dropdown: {
              basekey: "grid.search.vognnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
          regnr: {
            type: "text",
            label: "grid.search.label.regnr",
            placeholder: "grid.search.placeholder.regnr",
            width: "20ex",
            dropdown: {
              basekey: "grid.search.regnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
          vagtnr: {
            type: "number",
            interval: [
              {
                label: "grid.search.label.vagtnr.fra",
                placeholder: "grid.search.placeholder.vagtnr.fra"
              },
              {
                label: "grid.search.label.vagtnr.til",
                placeholder: "grid.search.placeholder.vagtnr.til"
              }
            ],
            placeholder: ""
          },
          chauffoernr: {
            type: "number",
            label: "grid.search.label.chauffoernr",
            placeholder: "grid.search.placeholder.chauffoernr"
          },
          startTs: {
            type: "date",
            label: "grid.search.label.startTs",
            placeholder: "grid.search.placeholder.startTs"
          },
          slutTs: {
            type: "date",
            label: "grid.search.label.slutTs",
            placeholder: "grid.search.placeholder.slutTs"
          }
        };
        return searchFields;
      }
      function defineTurSearchFields() {
        var searchFields = {
/*
           datasource: {
           type: "radio",
           radio: [{label: "grid.search.label.vagt", value: "vagt"}, {label: "grid.search.label.tur", value: "tur"}],
           label: "grid.search.label.datakilde",
           placeholder: ""
           },
          selskabsnr: {
            type: "text",
            label: "grid.search.label.selskabsnr",
            placeholder: "grid.search.placeholder.selskabsnr",
            dropdown: {
              basekey: "grid.search.selskabsnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
*/
          vognnr: {
            type: "text",
            label: "grid.search.label.vognnr",
            placeholder: "grid.search.placeholder.vognnr",
            width: "15ex",
            dropdown: {
              basekey: "grid.search.vognnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
          regnr: {
            type: "text",
            label: "grid.search.label.regnr",
            placeholder: "grid.search.placeholder.regnr",
            width: "20ex",
            dropdown: {
              basekey: "grid.search.regnr.type.",
              range: [0], // add any new types to the list here e.g. range: [0, 1, 2, 3]. 0 ==> all types
              translatedrange: {0: {value: 0, istranslated: false}}
            }
          },
          vagtnr: {
            type: "number",
            interval: [
              {
                label: "grid.search.label.vagtnr.fra",
                placeholder: "grid.search.placeholder.vagtnr.fra"
              },
              {
                label: "grid.search.label.vagtnr.til",
                placeholder: "grid.search.placeholder.vagtnr.til"
              }
            ],
            placeholder: ""
          },
          turnr: {
            type: "number",
            interval: [
              {
                label: "grid.search.label.turnr.fra",
                placeholder: "grid.search.placeholder.turnr.fra"
              },
              {label: "grid.search.label.turnr.til", placeholder: "grid.search.placeholder.turnr.til"}
            ],
            placeholder: ""
          },
          chauffoernr: {
            type: "number",
            label: "grid.search.label.chauffoernr",
            placeholder: "grid.search.placeholder.chauffoernr"
          },
          startTs: {
            type: "date",
            label: "grid.search.label.startTs",
            placeholder: "grid.search.placeholder.startTs"
          },
          slutTs: {
            type: "date",
            label: "grid.search.label.slutTs",
            placeholder: "grid.search.placeholder.slutTs"
          }
        };
        return searchFields;
      }

    }
  ]);
