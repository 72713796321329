'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('mdDateLocaleService',
  ['$locale', '$translate', '$log', '$mdDateLocale',
    function ($locale, $translate, $log, $mdDateLocale) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables

      // api: public methods
      self.initLocale = function() {
        self.months         = $locale.DATETIME_FORMATS.MONTH;
        self.shortMonths    = $locale.DATETIME_FORMATS.SHORTMONTH;
        self.days           = $locale.DATETIME_FORMATS.DAY;
        self.shortDays      = $locale.DATETIME_FORMATS.SHORTDAY;
        // forkert i $locale? self.firstDayOfWeek = $locale.DATETIME_FORMATS.FIRSTDAYOFWEEK;
        self.firstDayOfWeek = 1;

        $mdDateLocale.months = self.months;
        $mdDateLocale.shortMonths = self.shortMonths;
        $mdDateLocale.days = self.days;
        $mdDateLocale.shortDays = self.shortDays;
        $mdDateLocale.firstDayOfWeek = self.firstDayOfWeek;
      };

      self.parseDate = function (dateString) {
        // DATETIME FORMAT DEPENDENCY
        if (dateString.search(/\d{2}[.]\d{2}[.]\d{4}/) > -1) {
          // DATETIME FORMAT DEPENDENCY
          var m = moment.tz(dateString, ['DD.MM.YYYY'], 'Europe/Berlin');
          return m.isValid() ? m.toDate() : new Date(NaN);
        }
        return new Date(NaN);
      };

      self.formatDate = function (date) {
        var m = moment(date);
        // DATETIME FORMAT DEPENDENCY
        return m.isValid() ? m.format('DD.MM.YYYY') : '';
      };

      self.monthHeaderFormatter = function (date) {
        return self.shortMonths[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
      };

      // In addition to date display, date components also need localized messages
      // for aria-labels for screen-reader users.
      self.weekNumberFormatter = function (weekNumber) {
        return $translate.instant('mddatelocale.ugenr.tekst') + ' ' + weekNumber;
      };

      self.msgCalendar = $translate.instant('mddatelocale.kalender.titel');
      self.msgOpenCalendar = $translate.instant('mddatelocale.kalender.button.aben');


      //initialization code
      self.initLocale();

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods
    }
  ]);
