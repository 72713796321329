'use strict';

angular.module('fiskal').filter('scaledNumber', ['numberFilter', '$log',
  function (numberFilter, $log) {
    return function (input, fractionSize, scale) {
      var _input = typeof input === 'number' ? input : parseFloat(input);
      if (_input === _input) {
        return numberFilter(_input * (scale || 1), fractionSize || 3);
      } else {
        return input;
      }
    };
  }]);
