"use strict";

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').controller('gridPopoverCellController',
  ['gridService', '$uibTooltip', '$scope', '$timeout', '$log',
    function (gridService, $uibTooltip, $scope, $timeout, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      // $log.debug("Entered gridPopoverCellController at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));

      //closure vars: private variables

      var openInProgress = false;
      var closeInProgress = false;

      var openTimeout = null;
      var closeTimeout = null;

      // api: public methods and fields
      self.closeDelay = 750;
      self.openDelay = 250;

      self.getGridDisplayedValue = gridService.getGridDisplayedValue;
      self.togglePopover = togglePopover;

      self.gridEnter = gridEnter;
      self.gridLeave = gridLeave;

      self.cellEnter = cellEnter;
      self.cellLeave = cellLeave;

      self.formatJson = formatJson;

      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function formatJson(json) {
        var obj = angular.fromJson(json);
        var txt = angular.toJson(obj, 4);
        return txt;
      }

      function togglePopover() {
        self.isopen = !self.isopen;
      }

      function open() {
        if (closeInProgress && closeTimeout) {
          $timeout.cancel(closeTimeout);
          closeInProgress = false;
        }
        if (!openInProgress) {
          openInProgress = true;
          openTimeout = $timeout(function () {
            self.isopen = true;
            openInProgress = false;
          }, self.openDelay);
        }
      }

      function close() {
        if (openInProgress && openTimeout) {
          $timeout.cancel(openTimeout);
          openInProgress = false;
        }
        if (!closeInProgress)
          closeInProgress = true;
        closeTimeout = $timeout(function () {
          self.isopen = false;
          closeInProgress = false;
        }, self.closeDelay);
      }

      function gridEnter($event) {
        $event.preventDefault();
        open();
        // $log.debug("gridEnter at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));
      }
      function gridLeave($event) {
        $event.preventDefault();
        close();
        // $log.debug("gridLeave at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));
      }
      function cellEnter($event) {
        $event.preventDefault();
        open();
        // $log.debug("cellEnter at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));
      }
      function cellLeave($event) {
        $event.preventDefault();
        close();
        // $log.debug("cellLeave at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));
      }


    }
  ]);
