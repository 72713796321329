angular.module('fiskal').config(['brugervognstateparams', '$stateProvider', '$urlServiceProvider', '$urlRouterProvider', '$locationProvider',
  function (brugervognstateparams, $stateProvider, $urlServiceProvider, $urlRouterProvider, $locationProvider) {

    var brugerVognParams = angular.merge({}, brugervognstateparams);

    $stateProvider
      .state('brugerVogn', {
        url: '^/brugervogn?' + Object.keys(brugerVognParams).join("&"),
        templateUrl: 'brugervognadmin/brugerVogn.html',
        controller: 'brugerVognController',
        controllerAs: 'ctrl',
        resolve: {},
        params: brugerVognParams,
        data: {
          ffNavbar: {
            display: 'navbar.state.brugervogn.display',
            roles: ['ROLE_ADMIN'],
            order: 2
          }
        }
      });
  }
]);

