'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridConfigTurService',
  ['gridService', 'uiGridConstants', '$translate', '$log',
    function (gridService, uiGridConstants, $translate, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables
      var _gridOptions = defineTurGridOptions();
      var _columnDefaults = defineTurColumnDefaults();
      var _columnPresets = defineTurColumnPresets();
      var _cellTooltips = defineTurCellTooltips();
      var _headerTooltips = defineTurHeaderTooltips();
      var _columnDefs = defineTurColDefs();
      var _columnLinks = defineTurColLinks();
      var _subgridColumnDefs = defineTurSubgridColumnDefs();
      var _subgridOptions = defineTurSubgridOptions();

      // api: public methods and properties
      self.getGridOptions = getGridOptions;
      self.getColumnDefaults = getColumnDefaults;
      self.getColumnPresets = getColumnPresets;
      self.getCellTooltips = getCellTooltips;
      self.getHeaderTooltips = getHeaderTooltips;
      self.getColumnDefs = getColumnDefs;
      self.getColumnLinks = getColumnLinks;
      self.getSubgridOptions = getSubgridOptions;
      self.getSubgridColumnDefs = getSubgridColumnDefs;

      self.getQuery = getQuery;

      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function getGridOptions() {
        return angular.copy(_gridOptions);
      }

      function getColumnDefaults() {
        return _columnDefaults;
      }

      function getColumnPresets() {
        return _columnPresets;
      }

      function getCellTooltips() {
        return _cellTooltips;
      }

      function getHeaderTooltips() {
        return _headerTooltips;
      }

      function getColumnDefs() {
        return _columnDefs;
      }

      function getColumnLinks() {
        return _columnLinks;
      }

      function getSubgridOptions() {
        return angular.copy(_subgridOptions);
      }

      function getSubgridColumnDefs() {
        return _subgridColumnDefs;
      }

      //CONFIGS

      function getQuery(qParams) {

        var query = Object.keys(qParams).reduce(function (acc, p) {

          switch (qParams[p] ? p : null) {
            case "selskabsnr":
            case "vognnr":
            case "chauffoernr":
              if (qParams[p] !== 0 && qParams[p] !== "0") {
                if (qParams[p] instanceof Array) {
                  acc.search.push({field: p, op: "in", args: qParams[p]});
                } else {
                  acc.search.push({field: p, op: "eq", args: [qParams[p]]});
                }
              }
              break;
            case "turnr":
            case "vagtnr":
              if (qParams[p].length > 0) {
                acc.search.push({field: p, op: "between", args: [ qParams[p][0], qParams[p][1] || qParams[p][0] ]});
              }
              break;
            case "slutTs":
              acc.search.push({field: 'startTs', op: "lt", args: [ gridService.toIsoTimestamp(qParams.slutTs, 1) ]});
              break;
            case "startTs":
              acc.search.push({field: 'startTs', op: "ge", args: [ gridService.toIsoTimestamp(qParams.startTs) ]});
              break;
          }
          return acc;
        }, {search: []});

        return query;

      }

      function defineTurGridOptions() {
        var gridoptions = {};
        return gridoptions;
      }

      function defineTurColumnDefaults() {
        var columnDefaults = {};
        return columnDefaults;
      }

      function defineTurColumnPresets() {
        var columnPresets = {};
        return columnPresets;
      }

      function defineTurCellTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var cellTooltips = {
          "momsprocent": ttf.showText("grid.tooltip.edit.momsprocent", getTextWrapper({target: {text: "grid.datasource.tur.momsprocent"}})),
          "indkoertTab": ttf.showText("grid.tooltip.edit.indkoertTab", getTextWrapper({target: {text: "grid.datasource.tur.indkoertTab"}})),
          "vagtnr": ttf.showText("grid.tooltip.navigate.turToVagt", getTextWrapper({
            target: {text: "grid.datasource.vagt"},
            source: {text: "grid.datasource.vagt"}
          })),
          "turnr": ttf.showTextWValues("grid.tooltip.userrow.add", ['vagtnr', 'turnr']),
          "*": ttf.showValue
        };

        Object.keys(cellTooltips).forEach(function (col) {
          cellTooltips[col] = wrapper(cellTooltips[col], isUserrow, editUserrow);
        });

        return cellTooltips;

        function getTextWrapper(properties) {
          return function () {
            return Object.keys(properties).reduce(function (acc, k) {
              var p = properties[k];
              var wrp = p.wrap ? p.wrap : "";
              acc[k] = wrp + $translate.instant(properties[k].text) + wrp;
              return acc;
            }, {});
          };
        }

        function isUserrow(row, col) {
          return row && row.entity && row.entity.turId < 0;
        }

        function editUserrow(row, col) {
          return ttf.showTextWValues("grid.tooltip.userrow.edit", 'turnr')(row, col);
        }

        function wrapper(defaultFn, conditionFn, specialFn) {
          return function (row, col) {
            if (conditionFn(row, col)) {
              return angular.isFunction(specialFn) ? specialFn(row, col) : specialFn;
            } else {
              return angular.isFunction(defaultFn) ? defaultFn(row, col) : defaultFn;
            }
          };
        }

      }

      function defineTurHeaderTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var headerTooltips = {
          "momsprocent": ttf.showText("grid.tooltip.header.edit.momsprocent", getTextWrapper({target: {text: "grid.datasource.tur.momsprocent"}})),
          "indkoertTab": ttf.showText("grid.tooltip.header.edit.indkoertTab", getTextWrapper({target: {text: "grid.datasource.tur.indkoertTab"}})),
          "vagtnr": ttf.showText("grid.tooltip.header.navigate.turToVagt", getTextWrapper({
            target: {text: "grid.datasource.vagt"},
            source: {text: "grid.datasource.tur"}
          })),
          "turnr": ttf.showText("grid.tooltip.header.userrow.add"),
          "*": ttf.empty
        };
        return headerTooltips;

        function getTextWrapper(properties) {
          return function () {
            return Object.keys(properties).reduce(function (acc, k) {
              var p = properties[k];
              var wrp = p.wrap ? p.wrap : "";
              acc[k] = wrp + $translate.instant(p.text) + wrp;
              return acc;
            }, {});
          };
        }


      }

      function defineTurColDefs() {

        var turCols = [
          {
            field: "turId",
            name: "turId",
            visible: false
          },
          {
            field: "selskabsnr",
            name: "selskabsnr",
            displayName: "grid.datasource.tur.selskabsnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 0
            //},
            presets: ['right'],
            visible: false
          },
          {
            field: "vognnr",
            name: "vognnr",
            displayName: "grid.datasource.tur.vognnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 1
            //},
            presets: ['right']
          },
          {
            field: "regnr",
            name: "regnr",
            displayName: "grid.datasource.tur.regnr",
            minWidth: 100,
            maxWidth: 120,

            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 1
            //},
            presets: ['right']
          },
          {
            field: "vagtnr",
            name: "vagtnr",
            displayName: "grid.datasource.tur.vagtnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 2
            //},
            presets: ['right']
          },
          {
            field: "turnr",
            name: "turnr",
            displayName: "grid.datasource.tur.turnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 4
            //},
            type: 'numberStr',
            presets: ['right']
          },
          {
            field: "chauffoernr",
            name: "chauffoernr",
            displayName: "grid.datasource.tur.chauffoernr",
            presets: ['right']
          },
          {
            field: "startTs",
            name: "startTs",
            displayName: "grid.datasource.tur.startTs",
            displayNameEdit: "grid.datasource.tur.startTs.edit",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 3
            //},
            minWidth: 120,
            maxWidth: 140,
            presets: ['datetime']
          },
          {
            field: "slutTs",
            name: "slutTs",
            displayName: "grid.datasource.tur.slutTs",
            displayNameEdit: "grid.datasource.tur.slutTs.edit",
            minWidth: 120,
            maxWidth: 140,
            presets: ['datetime']
          },
          {
            field: "tidPause",
            name: "tidPause",
            displayName: "grid.datasource.tur.tidPause",
            presets: ['duration']
          },
          {
            field: "distanceTotal",
            name: "distanceTotal",
            displayName: "grid.datasource.tur.distanceOptaget",
            minWidth: 95,
            maxWidth: 110,
            presets: ['km', 'aggKm']
          },
          {
            field: "betalingsart",
            name: "betalingsart",
            displayName: "grid.datasource.tur.betalingsart"
          },
          {
            field: "indkoertTaxameter",
            name: "indkoertTaxameter",
            minWidth: 100,
            maxWidth: 110,
            displayName: "grid.datasource.tur.indkoertTaxameter",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertFastpris",
            name: "indkoertFastpris",
            minWidth: 100,
            maxWidth: 110,
            displayName: "grid.datasource.tur.indkoertFastpris",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertTillaeg",
            name: "indkoertTillaeg",
            displayName: "grid.datasource.tur.indkoertTillaeg",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertTab",
            name: "indkoertTab",
            displayName: "grid.datasource.tur.indkoertTab",
            presets: ['kr', 'aggKr']
          },
          {
            field: "momsprocent",
            name: "momsprocent",
            displayName: "grid.datasource.tur.momsprocent",
            presets: ['momsprocent']
          },
          {
            field: "moms",
            name: "moms",
            displayName: "grid.datasource.tur.moms",
            presets: ['kr', 'aggKr']
          },
          {
            field: "betalingIalt",
            name: "betalingIalt",
            displayName: "grid.datasource.tur.betalingIalt",
            presets: ['kr', 'aggKr']
          },
          {
            field: "modChangelog",
            name: "modChangelog",
            displayName: "grid.datasource.tur.notes"
          },
          {
            presets: "spacer"
          },
          {
            field: "modId",
            name: "modId",
            visible: false
          },
          {
            field: "modOrigId",
            name: "modOrigId",
            visible: false
          },
          {
            field: "modOrigTurId",
            name: "modOrigTurId",
            visible: false
          },
          {
            field: "modType",
            name: "modType",
            visible: false
          },
          {
            field: "modDeleted",
            name: "modDeleted",
            visible: false
          },
          {
            field: "modCreatedBy",
            name: "modCreatedBy",
            visible: false
          },
          {
            field: "modCreatedAt",
            name: "modCreatedAt",
            presets: ['datetimesec'],
            visible: false
          }

        ];
        turCols['datasource'] = 'tur';
        return turCols;
      }

      function defineTurColLinks() {
        // please see config of $stateProvider in router.js
        var turColLinks = {
          vagtnr: {
            state: "vagt",
            params: {
              "datasource": null,
              /* "selskabsnr": "selskabsnr", */
              "vognnr": "vognnr",
              "vagtnr": ["vagtnr", "vagtnr"],
              "chauffoernr": "chauffoernr",
              "startTs": "startTs",
              "slutTs": "slutTs"
            }
          },
          '*': null

        };
        return turColLinks;
      }

      function defineTurSubgridOptions() {
        var subgridOptions = {};
        return subgridOptions;
      }

      function defineTurSubgridColumnDefs() {

        function userrow(row, col) {
          return row.entity.modType === "created";
        }

        function modified(row, col) {
          return row.entity.modType === "modified";
        }

        function allways(row, col) {
          return true;
        }

        var subgridColumnDefs = {
          modChangelog: [
            {
              field: "startTs",
              name: "notes.startTs",
              displayName: "grid.datasource.tur.subgrid.notes.starTs",
              minWidth: 120,
              maxWidth: 140,
              visibility: userrow,
              presets: ['left']
            },
            {
              field: "slutTs",
              name: "notes.slutTs",
              minWidth: 120,
              maxWidth: 140,
              displayName: "grid.datasource.tur.subgrid.notes.slutTs",
              visibility: userrow,
              presets: ['left']
            },
            {
              field: "distanceTotal",
              name: "notes.distanceTotal",
              displayName: "grid.datasource.tur.subgrid.notes.distanceTotal",
              visibility: userrow,
              presets: ['left']
            },
            {
              field: "betalingsart",
              name: "notes.betalingsart",
              displayName: "grid.datasource.tur.subgrid.notes.betalingsart",
              visibility: userrow,
              presets: ['left']
            },
            {
              field: "momsprocent",
              name: "notes.momsprocent",
              displayName: "grid.datasource.tur.subgrid.notes.momsprocent",
              visibility: allways,
              presets: ['left']
            },
            {
              field: "indkoertTab",
              name: "notes.indkoertTab",
              displayName: "grid.datasource.tur.subgrid.notes.indkoertTab",
              visibility: modified,
              presets: ['left']
            },
            {
              field: "betalingIalt",
              name: "notes.betalingIalt",
              displayName: "grid.datasource.tur.subgrid.notes.betalingIalt",
              visibility: userrow,
              presets: ['left']
            },
            {
              field: "notes",
              name: "notes.notes",
              displayName: "grid.datasource.tur.subgrid.notes.note",
              visibility: allways,
              presets: ['left']
            },
            {
              field: "modCreatedBy",
              name: "notes.modCreatedBy",
              displayName: "grid.datasource.tur.subgrid.notes.bruger",
              visibility: allways,
              presets: ['left']
            },
            {
              field: "modCreatedAt",
              name: "notes.modCreatedAt",
              displayName: "grid.datasource.tur.subgrid.notes.timestamp",
              visibility: allways,
              presets: ['left']
            }
          ]
        };
        return subgridColumnDefs;
      }

    }
  ]);
