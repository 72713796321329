angular.module('fiskal').service('momsDialogService', ['gridDataService', 'gridService', '$mdDialog', '$translate', '$injector', '$log',
  function (gridDataService, gridService, $mdDialog, $translate, $injector, $log) {

    var self = this; // return self;

    self.showMomsDialog = function (row, col, evt) {

      if (!angular.isDefined(row.entity[col.name]) || row.entity[col.name] === null) {
        return true; // is handled => true -- do not edit when momspct is not present
      }

      // ** Tilladte momsprocenter id 'ret-momsprocent-dropdown' **
      // NB! Skal disse rettes, skal man også rette i funktionen:
      // userRowDialogService.defineUserRowFields under momsporcent
      var select = [7000, 19000];

      dialogController['$inject'] = ['$scope', '$mdDialog', 'cellActionService'];
      var dlgConfig = configMoms(select, dialogController);

      showDialog(dlgConfig, $mdDialog);

      return true;

      function configMoms(select, dlgController) {
        var dlg = {
          controller: dlgController,
          controllerAs: 'dlg',
          bindToController: true,
          locals: {
            select: select,
            column: col,
            row: row
          },
          templateUrl: 'grid/cellaction/momsDialog.html',
          parent: angular.element(document.body),
          targetEvent: evt,
          clickOutsideToClose: false,
          disableParentScroll: false,
          fullscreen: false
        };
        return dlg;
      }

      function showDialog(dlgConfig, mdDialog) {
        mdDialog.show(dlgConfig).then(
          function (response) {

            $log.debug("in moms showDialog response: " + angular.toJson(response));

            var saveRow = row.entity;

            row.entity = angular.merge(row.entity, {
              momsprocent: response.momsprocent,
              moms: null
            });

            var updates = angular.merge({turId: row.entity.turId, modId: row.entity.modId}, response);

            //start save visualization
            Object.keys(updates).forEach(function(colName) {
              gridService.setSaving('tur', row, row.grid.getColDef(colName), true);
            });
            gridDataService.saveTurRow(updates).then(function (response) {
                $log.debug(angular.toJson(response));
                row.entity = angular.merge(row.entity, response);
                //stop save visualization
                Object.keys(updates).forEach(function(colName) {
                  gridService.setSaving('tur', row, row.grid.getColDef(colName), false);
                });
              },
              function (response) {
                row.entity = saveRow;
                //stop save visualization
                Object.keys(updates).forEach(function(colName) {
                  gridService.setSaving('tur', row, row.grid.getColDef(colName), false);
                });
                //todo complain
              });
          }, null);
      }

      function dialogController($scope, $mdDialog, cellActionService) {

        var dlg = this;

        // var grid = dlg.row.grid;
        var rowEntity = dlg.row.entity;
        var col = dlg.column;

        var colChanges = cellActionService.getColumnChangelog(rowEntity, [col.name]);


        dlg.select = dlg.select.map(function (value) {
          return gridService.formatAsDisplayValue(value, col.colDef);
        });

        dlg.val = gridService.formatAsDisplayValue(rowEntity[col.name], col.colDef);
        dlg.note = "";

        dlg.prevVal = colChanges.length > 0 ? gridService.formatAsDisplayValue(colChanges.slice(-1)[0].fields[col.name].old, col.colDef) : "";
        dlg.origVal = colChanges.length > 1 ? gridService.formatAsDisplayValue(colChanges[0].fields[col.name].old, col.colDef) : "";

        dlg.showPrevVal = dlg.prevVal !== "";
        dlg.showOrigVal = dlg.origVal !== "";
        dlg.showReset = dlg.showOrigVal || dlg.showPrevVal;

        dlg.startVal = dlg.val;

        dlg.hide = function () {
          $mdDialog.hide();
        };

        dlg.cancel = function () {
          $mdDialog.cancel();
        };

        dlg.save = function () {
          var response = {
            momsprocent: gridService.parseGridDisplayedValue(dlg.val, col.colDef),
            modType: "modified",
            notes: dlg.note
          };
          $mdDialog.hide(response);
        };
      }
    };

    return self;
  }
]);
