angular.module('fiskal').service('lossDialogService', ['gridDataService', 'gridService', '$mdDialog', '$translate', '$injector', '$sce', '$log',
  function (gridDataService, gridService, $mdDialog, $translate, $injector, $sce, $log) {

    var self = this; // return self;

    var BETALING_SAMLET = "betalingIalt";
    var LOSS = "indkoertTab";
    // var BETALING_IALT_IDX = 0;
    // var LOSS_IDX = 1;

    self.showLossDialog = function (row, col, evt) {

      // if (!angular.isDefined(row.entity[col.name]) || row.entity[col.name] === null) {
      //   return true; // is handled => true -- do not edit when momspct is not present
      // }

      var valueSrcs = [BETALING_SAMLET, LOSS];
      var select = [
        {
          src: '',
          text: $sce.trustAsHtml('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            '<md-icon style="color: rgb(200,200,200);"class="material-icons md-dark md-inactive">compare_arrows</md-icon>')
        },
        {
          src: BETALING_SAMLET,
          text: $sce.trustAsHtml('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            '<md-icon class="material-icons">arrow_forward</md-icon>')
        },
        {
          src: LOSS,
          text: $sce.trustAsHtml('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            '<md-icon class="material-icons">arrow_back</md-icon>')
        }
      ];

      dialogController['$inject'] = ['$scope', '$mdDialog', 'cellActionService'];
      var dlgConfig = configLossModal(select, dialogController, valueSrcs);

      showDialog(dlgConfig, $mdDialog);

      return true;

      function configLossModal(select, dlgController, valueSrcs) {
        var dlg = {
          controller: dlgController,
          controllerAs: 'dlg',
          bindToController: true,
          locals: {
            select: select,
            valueSrcs: valueSrcs,
            column: col,
            row: row
          },
          templateUrl: 'grid/cellaction/lossDialog.html',
          parent: angular.element(document.body),
          targetEvent: evt,
          clickOutsideToClose: false,
          disableParentScroll: false,
          fullscreen: false
        };
        return dlg;
      }

      function showDialog(dlgConfig, mdDialog) {
        mdDialog.show(dlgConfig).then(
          function (response) {

            $log.debug("in loss showDialog response: " + angular.toJson(response));

            var saveRow = row.entity;

            row.entity = angular.merge(row.entity, {
              indkoertTab: response.indkoertTab, betalingIalt: response.betalingIalt,
              moms: null
            });

            var updates = angular.merge({turId: row.entity.turId, modId: row.entity.modId}, response);
            //start save visualization
            Object.keys(updates).forEach(function(colName) {
              gridService.setSaving('tur', row, row.grid.getColDef(colName), true);
            });
            gridDataService.saveTurRow(updates).then(function (response) {
                $log.debug(angular.toJson(response));
                row.entity = angular.merge(row.entity, response);
                //stop save visualizartion
                Object.keys(updates).forEach(function(colName) {
                  gridService.setSaving('tur', row, row.grid.getColDef(colName), false);
                });
              },
              function (response) {
                row.entity = saveRow;
                //stop save visualizartion
                Object.keys(updates).forEach(function(colName) {
                  gridService.setSaving('tur', row, row.grid.getColDef(colName), false);
                });
                //todo complain
              });
          }, null);
      }

      function dialogController($scope, $mdDialog, cellActionService) {

        var dlg = this;

        var grid = dlg.row.grid;
        var rowEntity = dlg.row.entity;
        // var col = dlg.column;

        dlg.colDef = [];
        dlg.colname = [];
        dlg.val = [];
        dlg.valueLabel = [];
        dlg.oldVal = [];
        dlg.oldVal[0] = [];
        dlg.oldVal[1] = [];
        dlg.startVal = [];
        dlg.resetVal = [];

        var colChanges = cellActionService.getColumnChangelog(rowEntity, dlg.valueSrcs);

        dlg.valueSrcs.forEach(function (valSrc, idx) {
          dlg.colname[idx] = valSrc;
          dlg.colDef[idx] = grid.getColDef(valSrc);
          dlg.val[idx] = gridService.getGridDisplayedValue(rowEntity, dlg.colDef[idx]);
          dlg.valueLabel[idx] = dlg.colDef[idx].displayName;
          dlg.oldVal[0][idx] = colChanges.length > 0 ? gridService.formatAsDisplayValue(colChanges.slice(-1)[0].fields[valSrc].old, dlg.colDef[idx]) : "";
          dlg.oldVal[1][idx] = colChanges.length > 1 ? gridService.formatAsDisplayValue(colChanges[0].fields[valSrc].old, dlg.colDef[idx]) : "";
          dlg.startVal[idx] = dlg.val[idx];
          dlg.resetVal[idx] = dlg.val[idx];
        });

        dlg.valSrc = dlg.select[0];

        dlg.note = "";


        dlg.resetButtonLabels = ["grid.diaglog.edit.tab.previous", "grid.diaglog.edit.tab.original"];

        dlg.showOldVal = [];
        dlg.oldVal.forEach(function (ov, idx) {
          dlg.showOldVal[idx] = dlg.oldVal[idx].some(function (val) {
            return val !== "";
          });
        });
        dlg.showReset = dlg.showOldVal[0] || dlg.showOldVal[1];

        dlg.resetValues = function (resetIdx) {
          dlg.val.forEach(function (ov, idx) {
            dlg.val[idx] = dlg.oldVal[resetIdx][idx];
            dlg.resetVal[idx] = dlg.val[idx];
          });
          dlg.valSrc = dlg.select[0];
        };

        dlg.changeValueSrc = function () {
          $log.debug(JSON.stringify(dlg.valSrc));
          switch (dlg.valSrc.src) {
            case '':
              valueSrcs.forEach(function (src, idx) {
                dlg.val[idx] = dlg.startVal[idx];
              });
              break;
            case BETALING_SAMLET:
              dlg.val[0] = gridService.formatAsDisplayValue(0.0, dlg.colDef[0]);
              dlg.val[1] = dlg.resetVal[0];
              break;
            case LOSS:
              dlg.val[0] = dlg.resetVal[1];
              dlg.val[1] = gridService.formatAsDisplayValue(0.0, dlg.colDef[1]);
              break;
          }
        };

        dlg.changeDetected = function () {
          return (dlg.startVal[0] !== dlg.val[0] || dlg.startVal[1] !== dlg.val[1]);
        };

        dlg.hide = function () {
          $mdDialog.hide();
        };

        dlg.cancel = function () {
          $mdDialog.cancel();
        };

        dlg.save = function () {
          var response = {
            modType: "modified",
            notes: dlg.note
          };
          dlg.val.forEach(function (val, idx) {
            response[dlg.colname[idx]] = gridService.parseGridDisplayedValue(val, dlg.colDef[idx]);
          });
          $mdDialog.hide(response);
        };
      }
    };


    return self;
  }
])
;
