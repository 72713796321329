'use strict';

angular.module('fiskal').service('userDataService', ['installationvariants', '$http', '$log',
  function (installationvariants, $http, $log) {

    var self = this; // return self;

    self.getUserData = function getUserData(username) {
      return $http.post(installationvariants.resourcePrefix + "user/search", {search: [{field: "username", op: "eq", args: [username]}]})
        .then(function success(resp) {
          var vognnr = resp.data.map(function (r) {
            return r.vognnr;
          });
          return {"user": resp.data[0].username, "vognnr": vognnr};
        }, function error(resp) {
          $log.error("getUserData(" + username + "): HTTP status: " + resp.status + "status text: " + resp.statusText);
          return resp;
        });
    };

    return self;
  }]);


