'use strict';

angular.module('fiskal').constant('defaultsearchparams', {
    datasource: {
      type: "string",
      value: "",
      array: false,
      inherit: true,
      dynamic: false,
      squash: false
    },
    selskabsnr: {
      type: "string",
      value: "",
      array: false,
      inherit: true,
      dynamic: false,
      squash: true
    },
  vognnr: {
    type: "string",
    value: "",
    array: false,
    inherit: true,
    dynamic: false,
    squash: true
  },
  regnr: {
    type: "string",
    value: "",
    array: false,
    inherit: true,
    dynamic: false,
    squash: true
  },
    vagtnr: {
      type: "int",
      value: [], //[start, slut] inclusive
      array: true,
      inherit: true,
      dynamic: false,
      squash: true
    },
    chauffoernr: {
      type: "int",
      value: null,
      array: false,
      inherit: true,
      dynamic: false,
      squash: true
    },
    startTs: {
      type: "string",
      value: "",
      array: false,
      inherit: true,
      dynamic: false,
      squash: false
    },
    slutTs: {
      type: "string",
      value: "",
      array: false,
      inherit: true,
      dynamic: false,
      squash: false
    },
    showresult: {
      type: "bool",
      value: false,
      array: false,
      inherit: true,
      dynamic: false,
      squash: false
    },
  searchtrigger: {
      type: "int",
      value: null,
      array: false,
      inherit: true,
      dynamic: false,
      squash: false
    }
  }
);

