"use strict";

angular.module('ffSecurity',
  ['pascalprecht.translate', 'ffNotify', 'ui.router', 'ui.bootstrap', 'ngMaterial', 'ngLocale', 'ngMessages', 'ngSanitize'])
  .run(['loginDialogService', '$rootScope', function (loginDialogService, $rootScope) {

    $rootScope.$on('ffShowLoginPrompt', function (event, deferedLogin, userName, message) {
      loginDialogService.loginPrompt(deferedLogin, userName, message);
    });

  }])
  .provider('ffSecurityService',
    [function () {

      var me = this;

      me.auth = {
        resourcePrefix: '/api/',
        loginPath: 'login',
        logoutPath: 'logout',
        validatePath: 'validate',
        header: 'Authorization'
      };

      me.keys = {
        USER_NAME: 'username',
        PASSWORD: 'password',
        ROLES: 'roles',
        TOKEN_TYPE: 'token_type',
        ACCESS_TOKEN: 'access_token'
      };

      me.useSessionStorage = true;

      me.charlie = false;


      me.setAuth = function (authObj) {
        angular.merge(me.auth, getNotNull(authObj));
      };

      me.setKeys = function (keysObj) {
        angular.merge(me.keys, getNotNull(keysObj));
      };

      me.setUseSessionStorage = function (useSessionStorage) {
        me.useSessionStorage = useSessionStorage;
      };

      me.setCharlie = function (charlie) {
        me.charlie = charlie;
      };

      function getNotNull(conf) {
        var myconf = null;
        if (conf) {
          myconf = Object.keys(conf).reduce(function (acc, k) {
            if (conf[k]) {
              acc[k] = conf[k];
            }
            return acc;
          }, {});
        }
        return myconf ? myconf : {};
      }

      me.$get = ['$injector', function ($injector) {
        var self = this;

        var $q = $injector.get('$q');
        var $rootScope = $injector.get('$rootScope');

        var token = me.useSessionStorage ? angular.fromJson(sessionStorage.getItem('AUTH_TOKEN')) : null;

        self.url = {
          login: me.auth.resourcePrefix + me.auth.loginPath,
          logout: me.auth.resourcePrefix + me.auth.logoutPath,
          validate: me.auth.resourcePrefix + me.auth.validatePath
        };

        //api
        self.doAuth = doAuth;
        self.login = login;
        self.logout = logout;
        self.validate = validate;
        self.isAuthenticated = isAuthenticated;
        self.isProtected = isProtected;
        self.isSecurityApi = isSecurityApi;

        self.clearAuthToken = clearAuthToken;
        self.getAuthToken = getAuthToken;
        self.getUserName = getUserName;
        self.getUserRoles = getUserRoles;

        return self;

        //internal functions
        function login(message) {

          return getCredentials(message).then(
            function success(resp) {
                if (!resp) {
                  return null;
                }
                return doAuth(resp.userName, resp.password).then(
                  function success() {
                    return resp;
                  },
                  function error(resp) {
                    return login(resp);
                  }
                );
              },
              null /* ignore */
            );

          function getCredentials(message) {
            var deferedLoginPrompt = $q.defer();
            var loginPromptPromise = deferedLoginPrompt.promise;

            $rootScope.$emit('ffShowLoginPrompt', deferedLoginPrompt, getUserName(), message);

            return loginPromptPromise;
          }

        }

        function doAuth(user, pw) {
          var data = {};
          data[me.keys.USER_NAME] = user;
          data[me.keys.PASSWORD] = pw;

          if (me.charlie) {
            if ( user && pw && user.toLowerCase() === "arne" && pw.toLowerCase() === "and") {
              token = {};
              token.header = {};
              token.userName = user;
              token.roles = ['ARNE_AND'];
              token.tokenType = 'Bearer';
              token.accessToken = 'NO_TOKEN';
              token.header[me.auth.header] = token.tokenType + " " + token.accessToken;

              if (me.useSessionStorage) {
                sessionStorage.setItem('AUTH_TOKEN', angular.toJson(token));
              }
              return $q.resolve(token);

            } else {
              return $q.reject("uid: arne pw: and");
            }
          }

          var $http = $injector.get('$http');
          return $http.post(self.url.login, data).then(
            function success(resp) {
              token = {};
              token.header = {};
              token.userName = resp.data[me.keys.USER_NAME];
              token.roles = resp.data[me.keys.ROLES];
              token.tokenType = resp.data[me.keys.TOKEN_TYPE];
              token.accessToken = resp.data[me.keys.ACCESS_TOKEN];
              token.header[me.auth.header] = token.tokenType + " " + token.accessToken;

              if (me.useSessionStorage) {
                sessionStorage.setItem('AUTH_TOKEN', angular.toJson(token));
              }

              return token;
            },
            function error(resp) {
              return $q.reject("Fejl ved login");
            }
          );
        }

        function logout() {
          if (me.charlie) {
            clearAuthToken();
            return $q.resolve();
          }
          var $http = $injector.get('$http');
          return $http.post(self.url.logout).then(
            function success(resp) {
              clearAuthToken();
            },
            function error(resp) {
              clearAuthToken();
            }
          );

        }

        function validate() {
          var $http = $injector.get('$http');
          return $http.post(self.url.validate).then(
            function success(resp) {
              return true;
            },
            function error(resp) {
              clearAuthToken();
              return false;
            }
          );
        }

        function clearAuthToken() {
          if (token) {
            token = token.userName ? {userName: token.userName} : null;
            if (me.useSessionStorage) {
              sessionStorage.removeItem('AUTH_TOKEN');
            }
          }
        }

        function isProtected(url) {
          var deny = url.search(/\/api\/.*$/) !== -1;
          var allowed = url.search(/\/api\/(?:login)|(?:logout)|(?:validate)$/) !== -1;
          return deny ? !allowed : deny;
        }

        function isSecurityApi(url) {
          var allowed = url.search(/\/api\/(?:login)|(?:logout)|(?:validate)$/) !== -1;
          return allowed;
        }

        function isAuthenticated() {
          return !!token && !!token.header;
        }

        function getAuthToken() {
          return token;
        }

        function getUserName() {
          return token ? token.userName : null;
        }

        function getUserRoles() {
          var roles = token ? token.roles || [] : [];
          if (me.charlie && isAuthenticated()) {
            if (!roles.includes('ARNE_AND')) {
              roles.push('ARNE_AND');
            }
          }
          return roles;
        }

      }];
    }])
  .service('ffSecurityInterceptor', ['$injector', function ($injector) {

    var self = this; // return self;

    //closure vars

    var authPromise = null;

    var $q = $injector.get('$q');
    var $state = $injector.get('$state');
    var sec = $injector.get('ffSecurityService');

    //api

    self.request = request;
    self.requestError = requestError;
    self.response = response;
    self.responseError = responseError;

    //internal functions
    function request(config) {

      //if we are not authenticated launch authentication dialog
      if (!sec.isAuthenticated()) {
        if (!authPromise) {
          authPromise = sec.login();
        }
      }

      if (sec.isProtected(config.url)) {
        //authentication in progress or done: put on promise queue
        if (authPromise) {
          return authPromise.then(
            function success(resp) {
              return mergeHeaders(config);
            },
            function error(resp) {
              return $q.reject();
            });
        } else if (sec.isAuthenticated()) {
          //authenticated and no promise: just merge headers
          return mergeHeaders(config);
        }
      } else if (sec.isSecurityApi(config.url)) {
        //security api call: go ahead and merge any security headers no matter what
        return mergeHeaders(config);
      }
      // catch all: just return config
      return config;
    }

    function mergeHeaders(config) {
      if (sec.getAuthToken()) {
        angular.merge(config.headers, sec.getAuthToken().header);
      }
      return config;
    }

    function requestError(rejection) {
      return $q.reject(rejection);
    }

    function response(response) {
      return response;
    }

    function responseError(rejection) {
      var txt = null;

      //noinspection FallThroughInSwitchStatementJS
      switch (rejection.status) {
        case 401:
          txt = "security.login.message.401";
        // eslint-disable-line no-fallthrough
        case 403:
          txt = txt ? txt : "security.login.message.403";
          sec.clearAuthToken();
          if (![sec.url.login, sec.url.logout].includes(rejection.config.url)) {
            authPromise = sec.login(txt);
            authPromise.then(function (resp) {
              $state.reload();
            }, null);
          }
          break;
      }

      return $q.reject(rejection);
    }

    return self;
  }]);
