'use strict';

angular.module('fiskal').constant('vagtsearchparams',
  (function () {
    // DATETIME FORMAT DEPENDENCY
    var today = moment().tz('Europe/Berlin').startOf('day').format("DD.MM.YYYY");
    var firstDayInMonth = moment().tz('Europe/Berlin').startOf('month').startOf('day').format("DD.MM.YYYY");

    var definitions = {};

    var values = {
      datasource: {value: 'vagt'},
      selskabsnr: {value: '0'},
      vognnr: {value: '0'},
      regnr: {value: '0'},
      vagtnr: {value: [] /*[start, slut] inclusive*/},
      chauffoernr: {value: undefined},
      startTs: {value: firstDayInMonth},
      slutTs: {value: today},
      showresult: {value: false},
      searchtrigger:{value: undefined}
    };

    return angular.merge({}, definitions, values);

  })()
);

