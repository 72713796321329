'use strict';
/* eslint-disable */
angular.module('fiskal').service('gridConfigService',
  ['gridService', 'gridCellTemplateService', 'gridConfigDefaultsService', 'uiGridConstants', '$injector', '$log',
    function (gridService, gridCellTemplateService, gridConfigDefaultsService, uiGridConstants, $injector, $log) {

      var self = this; // return self;

      var DEFAULT = 'DEFAULT',
        VAGT = 'vagt',
        TUR = 'tur',
        VAGT_STATE = 'vagt',
        TUR_STATE = 'tur';

      var datasources = [VAGT, TUR];
      var _statemap = [];
      _statemap[VAGT] = VAGT_STATE;
      _statemap[TUR] = TUR_STATE;

      //======================================

      var gridOptions = {};
      var columnDefaults = {};
      var columnPresets = {};
      var columnDefs = [];
      var cellTooltips = {};
      var headerTooltips = {};
      var columnLinks = {};
      var subgridOptions = {};
      var subgridColumnDefs = {};

      var dsInstance = {};

      gridOptions[DEFAULT] = gridConfigDefaultsService.getGridOptions();
      columnDefaults[DEFAULT] = gridConfigDefaultsService.getColumnDefaults();
      columnPresets[DEFAULT] = gridConfigDefaultsService.getColumnPresets();
      cellTooltips[DEFAULT] = gridConfigDefaultsService.getCellTooltips();
      headerTooltips[DEFAULT] = gridConfigDefaultsService.getHeaderTooltips();
      columnDefs[DEFAULT] = gridConfigDefaultsService.getColumnDefs();
      columnLinks[DEFAULT] = gridConfigDefaultsService.getColumnLinks();
      subgridOptions[DEFAULT] = gridConfigDefaultsService.getSubgridOptions();
      subgridColumnDefs[DEFAULT] = gridConfigDefaultsService.getSubgridColumnDefs();

      datasources.forEach(function (dataSrc) {

        var ds = "gridConfig" + dataSrc.charAt(0).toUpperCase() + dataSrc.slice(1) + "Service";
        dsInstance[dataSrc] = $injector.get(ds);

        gridOptions[dataSrc] = angular.merge({}, gridOptions[DEFAULT], dsInstance[dataSrc].getGridOptions());
        columnDefaults[dataSrc] = angular.merge({}, columnDefaults[DEFAULT], dsInstance[dataSrc].getColumnDefaults());
        columnPresets[dataSrc] = angular.merge({}, columnPresets[DEFAULT], dsInstance[dataSrc].getColumnPresets());
        cellTooltips[dataSrc] = angular.merge({}, cellTooltips[DEFAULT], dsInstance[dataSrc].getCellTooltips());
        headerTooltips[dataSrc] = angular.merge({}, headerTooltips[DEFAULT], dsInstance[dataSrc].getHeaderTooltips());
        columnDefs[dataSrc] = buildColDefs(angular.merge([], columnDefs[DEFAULT], dsInstance[dataSrc].getColumnDefs()));
        columnLinks[dataSrc] = angular.merge({}, columnLinks[DEFAULT], dsInstance[dataSrc].getColumnLinks());
        subgridOptions[dataSrc] = angular.merge({}, subgridOptions[DEFAULT], dsInstance[dataSrc].getSubgridOptions());
        subgridColumnDefs[dataSrc] = angular.merge({}, subgridColumnDefs[DEFAULT], dsInstance[dataSrc].getSubgridColumnDefs());

      });

      var dateCols = {};
      var presetCols = {};
      var subgridPresetCols = {};

      var subgridOptionsStore = {};

      // expose methods on self

      self.getDateCols = getDateCols;
      self.getPresetCols = getPresetCols;
      self.getColumnDefs = getColumnDefs;
      self.getColumnLinks = getColumnLinks;
      self.setColProperty = setColProperty;
      self.getColProperty = getColProperty;
      self.getGridOptions = getGridOptions;
      self.getSubgridOptionsDefinition = getSubgridOptionsDefinition;
      self.getSubgridPresetCols = getSubgridPresetCols;
      self.getQuery = getQuery;

      self.getStatemap = function () {
        return _statemap
      };

      //internal functions

      function getQuery(qParams) {
        return dsInstance[qParams.datasource].getQuery(qParams);
      }

      function getDateCols(dataSrc) {
        if (!dateCols[dataSrc]) {
          if (getColumnDefs(dataSrc)) {
            dateCols[dataSrc] = getColumnDefs(dataSrc).filter(function (c) {
              return c.type === 'date';
            });
          } else {
            dateCols[dataSrc] = [];
          }
        }
        return dateCols[dataSrc];
      }

      function getColumnDefs(dataSrc) {
        return columnDefs[dataSrc];
      }

      function getPresetCols(preset, dataSrc) {
        if (!presetCols[dataSrc]) {
          presetCols[dataSrc] = {};
        }
        if (!presetCols[dataSrc][preset]) {
          presetCols[dataSrc][preset] = getColumnDefs(dataSrc).filter(function (c) {
            return c.presets ? c.presets.includes(preset) : false;
          });
        }
        return presetCols[dataSrc][preset];
      }

      function getColumnLinks(dataSrc, columnName) {
        var link = null;
        if (columnLinks && columnLinks[dataSrc]) {
          link = columnLinks[dataSrc][columnName];
          link = link ? link : columnLinks[dataSrc]["*"];
        }
        return link;
      }

      function setColProperty(dataSrc, columnName, property, value) {
        var idx = columnDefs[dataSrc].findIndex(function (el) {
          return el.name === columnName;
        });
        columnDefs[dataSrc][idx][property] = value;
      }

      function getColProperty(dataSrc, columnName, property) {
        var idx = columnDefs[dataSrc].findIndex(function (el) {
          return el.name === columnName;
        });
        return columnDefs[dataSrc][idx][property];
      }

      function getGridOptions(dataSrc) {
        if (dataSrc) {
          return gridOptions[dataSrc];
        } else {
          return gridOptions[DEFAULT];
        }
      }

      function getCellTooltip(columnName, dataSrc) {
        var tip = null;
        if (cellTooltips && cellTooltips[dataSrc]) {
          tip = cellTooltips[dataSrc][columnName];
          tip = tip ? tip : cellTooltips[dataSrc]["*"];
        }
        return tip;
      }

      function getHeaderTooltip(columnName, dataSrc) {
        var tip = null;
        if (headerTooltips && headerTooltips[dataSrc]) {
          tip = headerTooltips[dataSrc][columnName];
          tip = tip ? tip : headerTooltips[dataSrc]["*"];
        }
        return tip;
      }

      function buildColDefs(columnDefs) {
        if (!columnDefs || !columnDefs['datasource']) {
          return [];
        }
        var dataSrc = columnDefs['datasource'];

        return columnDefs.map(function (r) {
          var coldef = angular.merge({}, columnDefaults[dataSrc], r);
          if (coldef.presets) {
            var presets = coldef.presets instanceof Array ? coldef.presets : [coldef.presets];
            coldef = presets.reduce(function (acc, p) {
              return angular.merge(acc, columnPresets[dataSrc][p]);
            }, coldef);
          }
          coldef.cellTemplate = gridCellTemplateService.getCellTemplate(coldef.name, dataSrc);
          coldef.headerCellTemplate = gridCellTemplateService.getHeaderTemplate(coldef.name, dataSrc);
          coldef.cellTooltip = getCellTooltip(coldef.name, dataSrc);
          coldef.headerTooltip = getHeaderTooltip(coldef.name, dataSrc);
          coldef.headerCellClass = coldef.headerTooltip.name !== 'empty' ? "ff-action-col-header" : "";
          return coldef;
        });
      }

      function getSubgridOptionsDefinition(dataSrc) {

        var subgrid;
        if (!subgridOptionsStore[dataSrc]) {
          subgridOptionsStore[dataSrc] = {};
        }
        subgrid = subgridOptionsStore[dataSrc];
        subgrid.datasource = dataSrc;

        return function (row, col) {
          if (!subgrid[col.name]) {
            subgrid[col.name] = angular.merge({}, angular.copy(gridOptions[dataSrc]), subgridOptions[dataSrc]);
            subgridColumnDefs[dataSrc][col.name]['datasource'] = dataSrc;
            subgrid[col.name].columnDefs = buildColDefs(subgridColumnDefs[dataSrc][col.name]);
          }

          //provide a new subgrid definition for each cell
          var cellSubgrid = subgrid[col.name][row.uid];
          if (!cellSubgrid) {
            subgrid[col.name][row.uid] = angular.copy(subgrid[col.name]);
            cellSubgrid = subgrid[col.name][row.uid];

            //details
            // subgrid[col.name].columnDefs
            //   .filter(function (c) {
            //     var presets = c.presets || [];
            //     presets = c.presets instanceof Array ? c.presets : [c.presets];
            //     return presets && presets.includes('detail');
            //   })
            //   .forEach(function (c) {
            //     c.visible = gridService.showDetail
            //   });

            //visibility functions
            cellSubgrid.columnDefs
              .filter(function (c) {
                return c.visibility && angular.isFunction(c.visibility);
              })
              .forEach(function(c){
                c.visible = c.visibility(row, col);
              });

            cellSubgrid.data = row.entity[col.name];
            cellSubgrid.subgridParentCol = col.name;
            cellSubgrid.datasource = subgridOptions.datasource;
          }

          return cellSubgrid;
        }
      }

      function getSubgridPresetCols(preset, parentCol, dataSrc) {
        if (!subgridPresetCols[dataSrc]) {
          subgridPresetCols[dataSrc] = {};
        }
        if (!subgridPresetCols[dataSrc][parentCol]) {
          subgridPresetCols[dataSrc][parentCol] = {};
        }
        if (!subgridPresetCols[dataSrc][parentCol][preset]) {
          subgridPresetCols[dataSrc][parentCol][preset] = subgridColumnDefs[dataSrc][parentCol].filter(function (c) {
            if (c.presets) {
              return c.presets instanceof Array ? c.presets.includes(preset) : c.presets === preset;
            } else {
              return false;
            }
          });
        }
        return subgridPresetCols[dataSrc][parentCol][preset];
      }

      return self;
    }]);
