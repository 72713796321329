angular.module('fiskal').component('dropdownDialog', function () {

  //definition returned at end of function
  var dropdownDefinition = {
    require: {},
    template: ' \
        <div uib-dropdown uib-dropdown-toggle> \
          <div style="position: relative;"> \
          <span class="material-icons ff-dropdown-icon">arrow_drop_down</span>\
            <label for="{{$ctrl.name}}" ng-class="{\'ff-dialog-readonly\': $ctrl.field.readonly}">\
              <translate>{{$ctrl.field.label}}</translate> \
            </label> \
          </div> \
          <div style="position: relative;"> \
            <span ng-if="$ctrl.field.required && !$ctrl.field.readonly" class="ff-dialog-required">*</span> \
              <input style="max-width: 15ex;" type="text" name="{{$ctrl.name}}" \
                ng-required="$ctrl.field.required" \
                ng-model="$ctrl.field.value" \
                placeholder="{{$ctrl.translate($ctrl.field.placeholder)}}" readonly > \
           </div> \
           <ul style="max-width: 15ex; min-width: 15ex;" uib-dropdown-menu> \
             <li ng-repeat="(k, e) in $ctrl.field.dropdown.translatedrange" ng-click="$ctrl.updateModel(k)"> \
               {{e.formatted}} \
             </li> \
           </ul> \
        </div> \
      ',
    // or function(tElement, tAttrs) { ... },
    // or templateUrl: 'directive.html',
    bindings: {
      form: "<",
      name: "<",
      field: "<"
    },
    controller: ['$scope', '$element', '$attrs', '$transclude', '$injector', ctrl]
//      link: postLink
  };

  //controller
  function ctrl($scope, $element, $attrs, $transclude, $injector) {
    var self = this; // return self;

    var $log = $injector.get('$log');
    var gridService = $injector.get('gridService');
    var $translate = $injector.get('$translate');

    var deregs = [];

    self.$onInit = function () {
      $log.debug("$onInit: " + self.name);

      var transRange = self.field.dropdown.translatedrange;

      // watch for translations
      deregs = Object.keys(transRange)
        .reduce(function (acc, r) {
          acc.push(watchDropdownTranslation(self.field, r));
          return acc;
        }, deregs);

      //format untranslated values
      transRange = Object.keys(transRange)
        .reduce(function (acc, key) {
          acc[key].formatted = gridService.formatAsDisplayValue(acc[key].value, self.field.coldef);
          return acc;
        }, transRange);

      //async translation
      gridService.translate(self.name, self.field.dropdown);

      //watch and update dropdowns for translations and formatting
      function watchDropdownTranslation(field, key) {

        return $scope.$watch(getDirty, formatDisplayValue);

        function getDirty(scope) {
          return field.dropdown.translatedrange[key] ? field.dropdown.translatedrange[key].value : null;
        }

        function formatDisplayValue(newVal, oldVal) {
          field.dropdown.translatedrange[key].formatted = gridService.formatAsDisplayValue(newVal, field.coldef);
        }
      }

    };

    self.$postLink = function () {
      $log.debug("$postLink: " + self.name);
      var control = self.form[self.name];
      if (control) {
        control.$formatters.push(function (rawValue) {
          if (self.field.dropdown.translatedrange && self.field.dropdown.translatedrange[rawValue]) {
            return self.field.dropdown.translatedrange[rawValue].formatted;
          } else {
            return gridService.formatAsDisplayValue(rawValue, self.field.coldef);
          }
        });
        control.$parsers.push(function (displayValue) {
          var tRange = self.field.dropdown.translatedrange;
          var val = Object.keys(tRange)
            .filter(function (k) {
              return tRange[k].formatted === displayValue;
            })
            .reduce(function (acc, k) {
              if (!acc) {
                acc = k;
              }
              return acc;
            }, null);
          return (val ? val : gridService.parseGridDisplayedValue(displayValue, self.field.coldef)) || "";
        });
      }
    };

    self.$doCheck = function () {
      var control = self.form[self.name];
      if (control) {
        var tRange = self.field.dropdown.translatedrange;
        var value = self.field.value;
        if (tRange[value] && !tRange[value].status && tRange[value].istranslated && control.$viewValue !== tRange[value].formatted) {
          control.$setViewValue(tRange[self.field.value].formatted);
          control.$render();
          tRange[value].status = "rendered";
        } else if (tRange[value] && tRange[value].status === "rendered") {
          control.$setPristine();
          control.$setUntouched();
          tRange[value].status = "done";
        }
      }
    };

    self.$onDestroy = function () {
      deregs.forEach(function (f) {
        f();
      });
    };

    self.translate = function(symbol){
      return $translate.instant(symbol);
    };

    self.updateModel = function (rawValue) {
      self.field.value = rawValue;
      var control = self.form[self.name];
      control.$commitViewValue();
      control.$setDirty();
      control.$setTouched();
    };
  }

  return dropdownDefinition;

}());
