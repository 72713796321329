'use strict';

angular.module('fiskal').service('configService', ['$log',
  function ($log) {

    var self = this; // return self;

    //closure vars
    var myUserData = null;

    // expose methods on self

    self.setUserData = function setUserData(userData) {
      myUserData = userData;
      return userData;
    };

    self.getUserData = function getUserData() {
      return myUserData;
    };

    return self;
  }
]);
