'use strict';

angular.module('fiskal').constant('brugervognstateparams',
  (function () {
    var definitions = {
      bruger: {
        type: "string",
        value: [],
        array: true,
        inherit: true,
        dynamic: true,
        squash: false
      },
      vogn: {
        type: "string",
        value: [],
        array: true,
        inherit: true,
        dynamic: true,
        squash: false
      }
    };

    var values = {
      bruger: {value: []},
      vogn: {value: []}
    };

    return angular.merge({}, definitions, values);

  })()
);
