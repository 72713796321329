angular.module('fiskal').directive('brugerVognSearch', ['utilService', 'brugerVognService', 'brugerVognDataService', '$state', '$q', '$log',
  function (utilService, brugerVognService, brugerVognDataService, $state, $q, $log) {

    var module = "search";

    var directiveDefinitionObject = {
      templateUrl: 'brugervognadmin/brugerVognSearch.html',
      restrict: 'E',
      scope: {
        src: "=",
        plexus: "="
      },
      link: postLink
    };

    return directiveDefinitionObject;

    function postLink(scope, iElement, iAttrs) {

      var src = scope.src;
      var conf = scope.plexus.config[module][src];
      var data = scope.plexus.data[src];
      var params = $state.params;

      var deregs = []; // eventhandlers to deregister

      scope.conf = conf;
      scope.module = module;

      deregs.push(query());

      var searchIdxKey = scope.src + "_search_idx";
      var searchIdx = utilService.getFromSession(searchIdxKey);
      if (searchIdx && searchIdx > -1) {
        setCurentSearch(searchIdx);
      } else {
        setCurentSearch(findSearchIdx(conf.defaultKey));
      }

      scope.getBackendCom = brugerVognService.getBackendCom;


      scope.setCurentSearch = setCurentSearch;
      scope.submitSearch = submitSearch;

      function setCurentSearch(idx) {
        scope.currentSearch = conf.searches[idx];
        utilService.saveToSession(searchIdxKey, idx);
      }

      function submitSearch() {
        var searchStr = scope.searchString;
        var searchObj = scope.currentSearch;
        params[src][0] = searchObj.key;
        params[src][1] = searchStr;
        params[src][2] = new Date().valueOf();
      }

      function findSearchIdx(key) {
        return conf.searches.findIndex(function (s) {
          return s.key === key;
        });
      }


      function query() {

        return scope.$watch(getQuery, updateQuery);

        function getQuery() {
          var q = params[src] || [];
          return q.length > 0 ? [src].concat(q).join("_") : "";
        }

        function updateQuery(newVal, oldVal) {
          if (newVal !== null) {
            var searchKey = params[src][0];
            var searchTerm = params[src][1];
            scope.searchString = searchTerm;
            if (searchKey && searchTerm) {
              setCurentSearch(findSearchIdx(searchKey));
              if (!scope.currentSearch) {
                setCurentSearch(findSearchIdx(conf.defaultKey));
              }
              var q = scope.currentSearch.query(searchTerm);
              $log.info("source: " + src + " -- query: " + angular.toJson(q));
              brugerVognService.setBackendCom(true);
              brugerVognDataService.searchBrugerVogn(src, q).then(
                function success(resp) {
                  data.searchResponse = angular.copy(sortEntities(resp));
                  brugerVognService.setBackendCom(false);
                },
                function error(resp) {
                  //todo complain
                  brugerVognService.setBackendCom(false);
                  $q.reject(resp);
                });
              $state.go($state.current.name, params);
            }
          }
        }
      }

      function sortEntities(entities) {
        if (!entities) {
          return entities;
        }
        //could be implemented simpler using String.localeCompare(). I am just not
        // confident, I would be able to mare it work cross-browser, though
        var keyedEntities = entities.reduce(function (acc, r) {
          acc[r[src === "bruger" ? "username" : "vognnr"]] = r;
          return acc;
        }, {});
        var keys = Object.keys(keyedEntities).sort();
        entities = keys.map(function (k) {
          return keyedEntities[k];
        });
        return entities;
      }

      //clean up event handlers
      scope.$on('$destroy', function () {
        deregs.forEach(function (dereg) {
          dereg();
        });
      });

    }

  }]);


