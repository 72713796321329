'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridConfigVagtService',
  ['gridService', 'uiGridConstants', '$translate', '$log',
    function (gridService, uiGridConstants, $translate, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      //closure vars: private variables
      var _gridOptions = defineVagtGridOptions();
      var _columnDefaults = defineVagtColumnDefaults();
      var _columnPresets = defineVagtColumnPresets();
      var _cellTooltips = defineVagtCellTooltips();
      var _headerTooltips = defineVagtHeaderTooltips();
      var _columnDefs = defineVagtColumnDefs();
      var _columnLinks = defineVagtColumnLinks();
      var _subgridOptions = defineVagtSubgridOptions();
      var _subgridColumnDefs = defineVagtSubgridColumnDefs();

      // api: public methods and properties
      self.getGridOptions = getGridOptions;
      self.getColumnDefaults = getColumnDefaults;
      self.getColumnPresets = getColumnPresets;
      self.getCellTooltips = getCellTooltips;
      self.getHeaderTooltips = getHeaderTooltips;
      self.getColumnDefs = getColumnDefs;
      self.getColumnLinks = getColumnLinks;
      self.getSubgridOptions = getSubgridOptions;
      self.getSubgridColumnDefs = getSubgridColumnDefs;

      self.getQuery = getQuery;

      //initialization code

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function getGridOptions() {
        return angular.copy(_gridOptions);
      }

      function getColumnDefaults() {
        return _columnDefaults;
      }

      function getColumnPresets() {
        return _columnPresets;
      }

      function getCellTooltips() {
        return _cellTooltips;
      }

      function getHeaderTooltips() {
        return _headerTooltips;
      }

      function getColumnDefs() {
        return _columnDefs;
      }

      function getColumnLinks() {
        return _columnLinks;
      }

      function getSubgridOptions() {
        return angular.copy(_subgridOptions);
      }

      function getSubgridColumnDefs() {
        return _subgridColumnDefs;
      }

      //CONFIGS

      function getQuery(qParams) {

        var query = Object.keys(qParams).reduce(function (acc, p) {
          switch (qParams[p] ? p : null) {
            case "selskabsnr":
            case "vognnr":
            case "regnr":
            case "chauffoernr":
              if (qParams[p] !== 0 && qParams[p] !== "0") {
                if (qParams[p] instanceof Array) {
                  acc.search.push({field: p, op: "in", args: qParams[p]});
                } else {
                  acc.search.push({field: p, op: "eq", args: [qParams[p]]});
                }
              }
              break;
            case "vagtnr":
              if (qParams.vagtnr.length > 0) {
                acc.search.push({field: p, op: "between", args: [ qParams[p][0], qParams[p][1] || qParams[p][0] ]});
              }
              break;
            case "slutTs":
              acc.search.push({field: 'startTs', op: "lt", args: [ gridService.toIsoTimestamp(qParams.slutTs, 1) ]});
              break;
            case "startTs":
              acc.search.push({field: 'startTs', op: "ge", args: [ gridService.toIsoTimestamp(qParams.startTs) ]});
              break;
          }
          return acc;
        }, {search:[]});

        return query;

      }


      function defineVagtGridOptions() {
        var gridoptions = {};
        return gridoptions;
      }

      function defineVagtColumnDefaults() {
        var columnDefaults = {};
        return columnDefaults;
      }

      function defineVagtColumnPresets() {
        var columnPresets = {};
        return columnPresets;
      }

      function defineVagtCellTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var cellTooltips = {
          "vagtnr": ttf.showText("grid.tooltip.navigate.vagtToTure", getTextWrapper({
            target: {text: "grid.datasource.tur.plural"},
            source: {text: "grid.datasource.vagt"}
          })),
          "*": ttf.showValue
        };

        function getTextWrapper(properties) {
          return function () {
            return Object.keys(properties).reduce(function (acc, k) {
              var p = properties[k];
              var wrp = p.wrap ? p.wrap : "";
              acc[k] = wrp + $translate.instant(properties[k].text) + wrp;
              return acc;
            }, {});
          };
        }

        return cellTooltips;
      }

      function defineVagtHeaderTooltips() {
        var ttf = gridService.getTooltipFunctions();
        var headerTooltips = {
          "vagtnr": ttf.showText("grid.tooltip.header.navigate.vagtToTure", getTextWrapper({
              target: {text: "grid.datasource.tur.plural"},
              source: {text: "grid.datasource.vagt"}
          })),
          "*": ttf.empty
        };

        function getTextWrapper(properties) {
          return function () {
            return Object.keys(properties).reduce(function (acc, k) {
              var p = properties[k];
              var wrp = p.wrap ? p.wrap : "";
              acc[k] = wrp + $translate.instant(properties[k].text) + wrp;
              return acc;
            }, {});
          };
        }

        return headerTooltips;
      }

      function defineVagtColumnDefs() {

        var columnDefs = [
          {
            field: "selskabsnr",
            name: "selskabsnr",
            displayName: "grid.datasource.vagt.selskabsnr",
            sort: {
              direction: uiGridConstants.ASC,
              priority: 0
            },
            presets: ['right'],
            visible: false
          },
          {
            field: "vognnr",
            name: "vognnr",
            displayName: "grid.datasource.vagt.vognnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 1
            //},
            presets: ['right']
          },
          {
            field: "regnr",
            name: "regnr",
            displayName: "grid.datasource.vagt.regnr",
            minWidth: 100,
            maxWidth: 120,
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 1
            //},
            presets: ['right']
          },
          {
            field: "vagtnr",
            name: "vagtnr",
            displayName: "grid.datasource.vagt.vagtnr",
            //sort: {
            //  direction: uiGridConstants.ASC,
            //  priority: 2
            //},
            presets: ['right']
          },
          {
            field: "chauffoernr",
            name: "chauffoernr",
            displayName: "grid.datasource.vagt.chauffoernr",
            presets: ['right']
          },
          {
            field: "startTs",
            name: "startTs",
            displayName: "grid.datasource.vagt.startTs",
            minWidth: 120,
            maxWidth: 140,
            presets: 'datetime'
          },
          {
            field: "slutTs",
            name: "slutTs",
            displayName: "grid.datasource.vagt.slutTs",
            minWidth: 120,
            maxWidth: 140,
            presets: 'datetime'
          },
          {
            field: "tidVagt",
            name: "tidVagt",
            displayName: "grid.datasource.vagt.tidVagt",
            presets: ['duration', 'aggDuration']
          },
          {
            field: "tidPause",
            name: "tidPause",
            displayName: "grid.datasource.vagt.tidPause",
            presets: ['duration', 'aggDuration']
          },
          {
            field: "distanceVagt",
            name: "distanceVagt",
            displayName: "grid.datasource.vagt.distanceVagt",
            presets: ['km', 'aggKm']
          },
          {
            field: "distanceOptaget",
            name: "distanceOptaget",
            displayName: "grid.datasource.vagt.distanceOptaget",
            minWidth: 95,
            maxWidth: 110,
            presets: ['km', 'aggKm']
          },
          {
            field: "antalTure",
            name: "antalTure",
            displayName: "grid.datasource.vagt.antalTure",
            presets: ['integer', 'aggInt']
          },
          {
            field: "indkoertTaxameter",
            name: "indkoertTaxameter",
            minWidth: 100,
            maxWidth: 110,
            displayName: "grid.datasource.vagt.indkoertTaxameter",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertFastpris",
            name: "indkoertFastpris",
            minWidth: 100,
            maxWidth: 110,
            displayName: "grid.datasource.vagt.indkoertFastpris",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertTillaeg",
            name: "indkoertTillaeg",
            displayName: "grid.datasource.vagt.indkoertTillaeg",
            presets: ['kr', 'aggKr']
          },
          {
            field: "indkoertTab",
            name: "indkoertTab",
            displayName: "grid.datasource.vagt.indkoertTab",
            presets: ['kr', 'aggKr']
          },
          {
            field: "betalingKontant",
            name: "betalingKontant",
            displayName: "grid.datasource.vagt.betalingKontant",
            presets: ['kr', 'aggKr']
          },
          {
            field: "betalingKredit",
            name: "betalingKredit",
            displayName: "grid.datasource.vagt.betalingKredit",
            presets: ['kr', 'aggKr']
          },
          {
            field: "betalingKonto",
            name: "betalingKonto",
            displayName: "grid.datasource.vagt.betalingKonto",
            presets: ['kr', 'aggKr']
          },
          {
            field: "momsbeloebSamlet",
            name: "momsbeloebSamlet",
            displayName: "grid.datasource.vagt.momsbeloebSamlet",
            presets: ['kr', 'aggKr']
          },
          {
            field: "betalingSamlet",
            name: "betalingIalt",
            displayName: "grid.datasource.vagt.betalingIalt",
            presets: ['kr', 'aggKr']
          },
          {
            field: "modId",
            name: "modId",
            visible: false
          },
          {
            field: "modified",
            name: "modified",
            visible: false
          },


          {
            presets: "spacer"
          }
        ];
        columnDefs['datasource'] = "vagt";
        return columnDefs;
      }

      function defineVagtColumnLinks() {
        // please see config of $stateProvider in router.js
        var columnLinks = {
          vagtnr: {
            state: "tur",
            params: {
              "datasource": null,
              /* "selskabsnr": "selskabsnr", */
              "vognnr": "vognnr",
              "vagtnr": ["vagtnr", "vagtnr"],
              "chauffoernr": "chauffoernr",
              "startTs": "startTs",
              "slutTs": "slutTs"
            }
          },
          '*': null
        };
        return columnLinks;
      }

      function defineVagtSubgridOptions() {
        var subgridOptions = {};
        return subgridOptions;
      }

      function defineVagtSubgridColumnDefs() {
        var subgridColumnDefs = {};
        return subgridColumnDefs;
      }

    }
  ]);
