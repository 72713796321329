'use strict';

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').service('gridCellTemplateService',
  ['$templateCache', '$log',
    function ($templateCache, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR


      //initialization code
      if (!$templateCache.get('subgrid.html')) {
        $templateCache.put('subgrid.html', defineSubgridTemplate());
      }
      if (!$templateCache.get('textbox.html')) {
        $templateCache.put('textbox.html', defineTextboxTemplate());
      }

      //closure vars: private variables
      var _toolTipAttributeDecoration = defineTooltip;
      var _subgridPopoverAttributeDecoration = definePopover('subgrid.html');
      // var _textboxPopoverAttributeDecoration = definePopover('textbox.html');
      var _defaultCellTemplate = defineDefaultCellTemplate;
      var _defaultHeaderTemplate = defineDefaultHeaderTemplate;
      var _oneToManyCellTemplate = defineOneToManyCellTemplate;
      // var _textboxPopoverCellTemplate = defineTextboxPopoverCellTemplate;
      var _cellTemplateDefinition = defineCellTemplateDefinitions();
      var _headerTemplateDefinition = defineHeaderTemplateDefinitions();

      // api: public methods
      self.getCellTemplate = getCellTemplate;
      self.getHeaderTemplate = getHeaderTemplate;


      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function defineCellTemplateDefinitions() {
        return {
          vagt: {
            "*": {
              template: _defaultCellTemplate,
              decoration: _toolTipAttributeDecoration
            }
          },
          tur: {
            modChangelog: {
              template: _oneToManyCellTemplate,
              decoration: _subgridPopoverAttributeDecoration
            },
            "*": {
              template: _defaultCellTemplate,
              decoration: _toolTipAttributeDecoration
            }
          }
        };
      }

      function defineHeaderTemplateDefinitions() {
        return {
          vagt: {
            "*": {
              template: _defaultHeaderTemplate,
              decoration: _toolTipAttributeDecoration
            }
          },
          tur: {
            "*": {
              template: _defaultHeaderTemplate,
              decoration: _toolTipAttributeDecoration
            }
          }
        };
      }

      function getCellTemplate(columnName, dataSrc) {
        var cellTemplate = null;
        if (_cellTemplateDefinition && _cellTemplateDefinition[dataSrc]) {
          var cellTemplateDefinition = _cellTemplateDefinition[dataSrc][columnName];
          cellTemplateDefinition = cellTemplateDefinition ? cellTemplateDefinition : _cellTemplateDefinition[dataSrc]["*"];
          cellTemplate = cellTemplateDefinition.template(cellTemplateDefinition.decoration);
        }
        //$log.debug(dataSrc + ":" + columnName + " ==> " + cellTemplate);
        return cellTemplate;
      }

      function getHeaderTemplate(columnName, dataSrc) {
        var headerTemplate = null;
        if (_headerTemplateDefinition && _headerTemplateDefinition[dataSrc]) {
          var headerTemplateDefinition = _headerTemplateDefinition[dataSrc][columnName];
          headerTemplateDefinition = headerTemplateDefinition ? headerTemplateDefinition : _headerTemplateDefinition[dataSrc]["*"];
          headerTemplate = headerTemplateDefinition.template(headerTemplateDefinition.decoration);
        }
        // $log.debug(dataSrc + ":" + columnName + " ==> " + headerTemplate);
        return headerTemplate;
      }


      //BEGIN: Templates stored in template cache

      //textbox.html
      function defineTextboxTemplate() {
        return ' \
        <div \
          style="white-space: pre-wrap; font-family: Consolas, \'Lucida Console\', Menlo, Monaco, Courier, \'Courier New\', monospace;" \
          ng-mouseenter="popCtrl.gridEnter($event)" \
          ng-mouseleave="popCtrl.gridLeave($event)" \
          >{{popCtrl.formatJson(!!row ? col.cellTooltip(row, col) : col.headerTooltip(col))}}</div> \
        </div> \
        ';
      }

      //subgrid.html
      function defineSubgridTemplate() {
        return ' \
        <div \
          ng-mouseenter="popCtrl.gridEnter($event)" \
          ng-mouseleave="popCtrl.gridLeave($event)" \
          ng-controller="gridSubgridController as subgridCtrl"> \
            <div class="ff-subgrid" ng-style="subgridCtrl.tableStyle" \
              ui-grid="subgridCtrl.setSubgridOptions(grid.appScope.getSubgridOptions(row, col))" \
              ui-grid-resize-columns \
              ui-grid-empty-base-layer \
              > \
            </div> \
        </div> \
        ';
      }
      //END: Templates stored in template cache

      //BEGIN: Decorations
      function definePopover(template) {
        return function () {
          return ' \
                uib-popover-template="\''
            +
            template
            +
            '\'" \
            popover-append-to-body="\'false\'" \
            popover-is-open="popCtrl.isopen" \
            popover-trigger="\'none\'" \
            popover-placement="left-bottom" \
            ng-mouseenter="popCtrl.cellEnter($event)" \
            ng-mouseleave="popCtrl.cellLeave($event)" \
           ';
        };
      }

      function defineTooltip() {
        return ' \
                 uib-tooltip="{{!!row ? col.cellTooltip(row, col) : col.headerTooltip(col)}}" \
                 tooltip-placement="top" \
                 tooltip-append-to-body="\'true\'" \
                 tooltip-popup-close-delay="150" tooltip-popup-delay="750" \
               ';
      }

      //END: Decorations

      //BEGIN: HeaderTemplates
      function defineDefaultHeaderTemplate(decoration) {
        return ' \
        <div \
          role="columnheader" \
          ng-class="{ \'sortable\': sortable, \'ff-action-col-header\': col.headerCellClass}" \
          ui-grid-one-bind-aria-labelledby-grid="col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'" \
          aria-sort="{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}" \
          '
          +
          decoration()
          +
          ' \
          > \
          <div \
            role="button" \
            tabindex="0" \
            class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" \
            col-index="renderIndex" \
            > \
            <span \
              class="ui-grid-header-cell-label" \
              ui-grid-one-bind-id-grid="col.uid + \'-header-text\'" \
              > \
              {{ col.displayName CUSTOM_FILTERS }} \
            </span> \
         </div> \
         <div \
            role="button" \
            tabindex="0" \
            ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" \
            class="ui-grid-column-menu-button" \
            ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false" \
            ng-click="toggleMenu($event)" \
            ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" \
            ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" \
            aria-haspopup="true" \
            > \
            <i \
              class="ui-grid-icon-angle-down" \
              aria-hidden="true" \
              > \
              &nbsp; \
            </i> \
          </div> \
          <div ui-grid-filter></div> \
        </div> \
      ';

      }
      //END: HeaderTemplates

      //BEGIN: CellTemplates
      function defineDefaultCellTemplate(decoration) {
        return ' \
                 <div \
                     style="white-space: normal;" \
                     class="ui-grid-cell-contents" \
                     ng-class="{\'ff-modified-cell\': grid.appScope.isModified(row, col, $event), \'ff-saving-cell\': grid.appScope.isSaving(row, col, $event)}"\
                     ng-dblclick="grid.appScope.cellLink(row, col, $event)" \
               '
          +
          decoration()
          +
          ' \
              > \
              {{COL_FIELD CUSTOM_FILTERS}} \
            </div> \
          ';
      }

      // function defineTextboxPopoverCellTemplate(decoration) {
      //   return ' \
      //            <div ng-controller="gridPopoverCellController as popCtrl" \
      //                style="white-space: normal;" \
      //                class="ui-grid-cell-contents ff-relation-cell" \
      //                ng-dblclick="grid.appScope.cellLink(row, col, $event)" \
      //          '
      //     +
      //     decoration()
      //     +
      //     ' \
      //         > \
      //         <span translate>subgrid.textbox.popover.text</span> \
      //       </div> \
      //     ';
      // }

      function defineOneToManyCellTemplate(decoration) {
        return ' \
                 <div ng-if="row.entity[col.name].length" \
                     ng-controller="gridPopoverCellController as popCtrl" \
                     style="white-space: normal;" \
                     class="ui-grid-cell-contents ff-relation-cell" \
                     ng-class="{\'ff-modified-cell\': grid.appScope.isModified(row, col, $event), \'ff-saving-cell\': grid.appScope.isSaving(row, col, $event)}"\
                     ng-dblclick="grid.appScope.cellLink(row, col, $event)" \
               '
          +
          decoration()
          +
          ' \
              > \
              {{row.entity[col.name].length}} <span translate>subgrid.onetomany.relation.text</span> \
            </div> \
          ';
      }

      //END: CellTemplates

    }
  ]);
