angular.module('fiskal').service('userSettingsDialogService', ['$mdDialog', '$translate', '$injector', '$log',
  function ($mdDialog, $translate, $injector, $log) {

    var self = this; // return self;

    self.showUserSettingsDialog = function () {

      var evt = null;

      //Languages
      var languages = $translate.getAvailableLanguageKeys();

      dialogController['$inject'] = ['$scope', '$mdDialog', '$translate'];

      // api: public methods and fields

      self.language = $translate.use();

      self.getLanguageSymbol = getLanguageSymbol;


      var dlgConfig = configUserSettings(dialogController);

      showDialog(dlgConfig, $mdDialog);

      return true;

      function configUserSettings(dlgController) {
        var dlg = {
          controller: dlgController,
          controllerAs: 'dlg',
          bindToController: true,
          locals: {
            languages: languages,
            language: self.language
          },
          templateUrl: 'user/userSettingsDialog.html',
          parent: angular.element(document.body),
          targetEvent: evt,
          clickOutsideToClose: false,
          disableParentScroll: false,
          fullscreen: false
        };
        return dlg;
      }

      function getLanguageSymbol(language) {
        return language ? "usersettings.dialog.language." + language.slice(0, 2).trim() : "";

      }

      function showDialog(dlgConfig, mdDialog) {
        mdDialog.show(dlgConfig).then(
          function (response) {
            $log.debug("In userSettingsDialogService showDialog response: " + angular.toJson(response));
            $translate.use(response.language);
          });
      }

      function dialogController($scope, $mdDialog, $translate) {

        var dlg = this;


        dlg.getLanguageSymbol = getLanguageSymbol;

        dlg.hide = function () {
          $mdDialog.hide();
        };

        dlg.cancel = function () {
          $mdDialog.cancel();
        };

        dlg.save = function () {
          var response = {
            language: dlg.language
          };
          $mdDialog.hide(response);
        };
      }
    };

    return self;
  }
]);
