"use strict";

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').controller('gridContainerController',
  ['gridConfigService', '$uiRouter', '$log',
    function (gridConfigService, $uiRouter, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR
      var currentState = $uiRouter.stateService.current.name;
      $log.debug("Entered gridContainerController with state " + currentState + " at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));
      //closure vars: private variables

      // api: public methods and fields

      //initialization code

      //set state from param:datasoruce
      var params = $uiRouter.globals.params;
      var datasource = params.datasource || 'vagt';
      var nextState = gridConfigService.getStatemap()[datasource];
      if (currentState !== nextState) {
        $uiRouter.stateService.go(nextState);
      }


      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

    }
  ]);
