'use strict';

angular.module('fiskal').service('utilService', ['$log',
  function ($log) {

    var self = this; // return self;

    //closure vars

    //api
    self.saveToSession = saveToSession;
    self.getFromSession = getFromSession;
    self.removeFromSession = removeFromSession;

    return self;

    //internal functions

    function saveToSession(key, obj){
      return sessionStorage.setItem(key, angular.toJson(obj));
    }

    function getFromSession(key){
      return angular.fromJson(sessionStorage.getItem(key));
    }

    function removeFromSession(key){
      return sessionStorage.removeItem(key);
    }


  }]);


