"use strict";

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').controller('brugerVognController',
  ['brugerVognService', '$scope', '$log',
    function (brugerVognService, $scope, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR

      var plexus = {};
      plexus.config = getConfigDefinition();
      plexus.data = getDataDefinition();
      plexus.translate = getTranslateDefinition();
      plexus.errormsg = getErrormsgDefinition();

      $log.debug("Entered brugerVognController with state " + self.currentState + " at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));

      //closure vars: private variables


      // api: public methods and fields

      self.plexus = plexus;

      //new entities needs to geristered here due to scoping
      //but we realy just relay the event to the details-directive
      //via the plexus (using a state-flag and a watch)
      self.newEntity = newEntity;
      self.getRelation = getRelation;

      self.getBackendCom = brugerVognService.getBackendCom;

      //initialization code


      //END CONSTRUCTOR
      return self;

      //internal functions: private methods

      function newEntity(src) {
        if (!plexus.data[src].createEntity) {
          plexus.data[src].createEntity = true;
        }
      }

      function getRelation(entity) {
        return entity === 'bruger' ? 'vogn' : 'bruger';
      }

      function getErrormsgDefinition() {
        var errormsg = {
          required: "brugervognadmin.details.formvalidation.error.required"
        };
        return errormsg;
      }

      function getTranslateDefinition() {
        //OBS use lower case NOT camel case for property names !!!
        var translate = {
          entities: {
            bruger: {
              terminal: "brugervognadmin.entity.bruger",
              within:   "brugervognadmin.entity.bruger.within",
              pluralterminal: "brugervognadmin.entity.bruger.plural",
              pluralwithin:   "brugervognadmin.entity.bruger.plural.within",
              createNewEntity:   "brugervognadmin.entity.bruger.createNewEntity",
              vogn: {
                relationLabel: "brugervognadmin.entity.bruger.relation.vogn"
              },
              properties: {
                username: {
                  terminal: "brugervognadmin.property.bruger.username",
                  within: "brugervognadmin.property.bruger.username.within"
                },
                password: {
                  terminal: "brugervognadmin.property.bruger.password",
                  within: "brugervognadmin.property.bruger.password.within"
                },
                initialer: {
                  terminal: "brugervognadmin.property.bruger.initialer",
                  within: "brugervognadmin.property.bruger.initialer.within"
                },
                fornavn: {
                  terminal: "brugervognadmin.property.bruger.fornavn",
                  within: "brugervognadmin.property.bruger.fornavn.within"
                },
                efternavn: {
                  terminal: "brugervognadmin.property.bruger.efternavn",
                  within: "brugervognadmin.property.bruger.efternavn.within"
                },
                email: {
                  terminal: "brugervognadmin.property.bruger.email",
                  within: "brugervognadmin.property.bruger.email.within"
                },
                telefonnummer: {
                  terminal: "brugervognadmin.property.bruger.telefonnummer",
                  within: "brugervognadmin.property.bruger.telefonnummer.within"
                },
                enabled: {
                  terminal: "brugervognadmin.property.bruger.enabled",
                  within: "brugervognadmin.property.bruger.enabled.within"
                },
                passwordexpired: {
                  terminal: "brugervognadmin.property.bruger.passwordExpired",
                  within: "brugervognadmin.property.bruger.passwordExpired.within"
                },
                accountexpired: {
                  terminal: "brugervognadmin.property.bruger.accountExpired",
                  within: "brugervognadmin.property.bruger.accountExpired.within"
                },
                accountlocked: {
                  terminal: "brugervognadmin.property.bruger.accountLocked",
                  within: "brugervognadmin.property.bruger.accountLocked.within"
                },
                admin: {
                  terminal: "brugervognadmin.property.bruger.admin",
                  within: "brugervognadmin.property.bruger.admin.within"
                }
              }
            },
            vogn: {
              terminal: "brugervognadmin.entity.vogn",
              within: "brugervognadmin.entity.vogn.within",
              pluralterminal: "brugervognadmin.entity.vogn.plural",
              pluralwithin: "brugervognadmin.entity.vogn.plural.within",
              createNewEntity:   "brugervognadmin.entity.vogn.createNewEntity",
              bruger: {
                relationLabel: "brugervognadmin.entity.vogn.relation.bruger"
              },
              properties: {
                vognnr: {
                  terminal: "brugervognadmin.property.vogn.vognnr",
                  within: "brugervognadmin.property.vogn.vognnr.within"
                },
                regnr: {
                  terminal: "brugervognadmin.property.vogn.regnr",
                  within: "brugervognadmin.property.vogn.regnr.within"
                }
              }
            }
          }

        };
        return translate;
      }

      function getConfigDefinition() {
        return {
          search: {
            bruger: {
              defaultKey: "username",
              searches: [
                {key: "username", query: defaultQuery, translate: "brugervognadmin.search.dropdown.bruger.username"},
                {key: "fornavn", query: defaultQuery, translate: "brugervognadmin.search.dropdown.bruger.fornavn"},
                {key: "efternavn", query: defaultQuery, translate: "brugervognadmin.search.dropdown.bruger.efternavn"},
                {key: "initialer", query: defaultQuery, translate: "brugervognadmin.search.dropdown.bruger.initialer"},
                {key: "telefonnummer", query: defaultQuery, translate: "brugervognadmin.search.dropdown.bruger.telefonnummer"}
              ]

            },
            vogn: {
              defaultKey: "vognnr",
              searches: [
                {key: "vognnr", query: vognnrQuery, translate: "brugervognadmin.search.dropdown.vogn.vognnr"},
                {key: "regnr", query: defaultQuery, translate: "brugervognadmin.search.dropdown.vogn.regnr"}
              ]
            }
          },
          details: {
            bruger: {
              heading: ["username", "fornavn", "efternavn"],
              defaultSelected: 0,
              hide: ["id"],
              sortOrder: [
                {key: "id", type: 'text'},
                {key: "username", type: 'text', required: true},
                {key: "password", type: 'password'},
                {key: "initialer", type: 'text', required: true},
                {key: "fornavn", type: 'text', required: true},
                {key: "efternavn", type: 'text', required: true},
                {key: "email", type: 'text', required: true},
                {key: "telefonnummer", type: 'text'},
                {key: "enabled", type: 'checkbox', default: true},
                {key: "passwordExpired", type: 'checkbox', default: false},
                {key: "accountExpired", type: 'checkbox', default: false},
                {key: "accountLocked", type: 'checkbox', default: false},
                {key: "admin", type: 'checkbox', default: false}
              ]
            },
            vogn: {
              heading: ["vognnr", "regnr"],
              defaultSelected: 0,
              hide: ["id"],
              sortOrder: [
                {key: "id", type: 'text'},
                {key: "vognnr", type: 'text', required: true},
                {key: "regnr", type: 'text'}
              ]
            }
          },
          relation: {
            bruger: {
              relation: "vogne",
              searchKey: "vognnr",
              heading: ["vognnr", "regnr"],
              hide: ["id"],
              sortOrder: [
                {key: "id"},
                {key: "vognnr"},
                {key: "regnr"}
              ]
            },
            vogn: {
              relation: "brugere",
              searchKey: "username",
              heading: ["username", "fornavn", "efternavn"],
              hide: ["id"],
              sortOrder: [
                {key: "id"},
                {key: "username"},
                {key: "initialer"},
                {key: "fornavn"},
                {key: "efternavn"},
                {key: "email"},
                {key: "telefonnummer"}
              ]
            }
          }
        };

        function vognnrQuery(value) {
          var query = {};
          if (value.match(/^\d+$/)) {
            var fromValue, toValue;
            fromValue = value;
            toValue = value;
            if (value.length < 7) {
              while (fromValue.length < 7) {
                fromValue = fromValue + '0';
                toValue = toValue + '9';
              }
              query.search = [{field: this.key, op: "between", args: [parseInt(fromValue), parseInt(toValue)]}];
            } else {
              query.search = [{field: this.key, op: "eq", args: [value]}];
            }
          } else if (value === "*") {
            // hack to get all
            query.search = [{field: this.key, op: "between", args: [0, 9999999]}];
          }
          return query;
        }

        function defaultQuery(value) {
          var query = {};
          if (value.length > 0) {
            if (value === "*") {
              // Hack to get all
              query.search = [{field: this.key, op: "ne", args: ["dummy" + (new Date).getTime().toString()]}];
            } else {
              query.search = [{field: this.key, op: "ilike", args: ["%"+value+"%"]}];
            }
          }
          return query;
        }

      }

      function getDataDefinition() {
        return {
          bruger: {},
          vogn: {}
        };
      }
    }
  ]);
