angular.module('fiskal').service('cellActionService',
  ['gridConfigService', 'userRowDialogService', 'momsDialogService', 'lossDialogService', '$state', '$translate', '$injector', '$log',
    function (gridConfigService, userRowDialogService, momsDialogService, lossDialogService, $state, $translate, $injector, $log) {

      var self = this; // return self;

      //config cell action handlers
      var handlers = [
        {
          serviceName: 'momsDialogService',
          methodName: 'showMomsDialog',
          willHandle: function (dataSrc, row, col, evt) {
            return !isUserRow(dataSrc, row, col, evt) && col.name === "momsprocent";
          }
        },
        {
          serviceName: 'lossDialogService',
          methodName: 'showLossDialog',
          willHandle: function (dataSrc, row, col, evt) {
            return !isUserRow(dataSrc, row, col, evt) && col.name === "indkoertTab";
          }
        },
        {
          serviceName: 'userRowDialogService',
          methodName: 'showUserRowDialog',
          willHandle: function (dataSrc, row, col, evt) {
            return isUserRow(dataSrc, row, col, evt) || col.name === "turnr";
          }
        }
      ];

      //initialize cell action handlers
      handlers = handlers.map(function (h) {
        h.method = $injector.get(h.serviceName)[h.methodName];
        h.isHandled = false;
        return h;
      });


      // expose api

      //handle special cases for useractions (default double-click on cell)
      self.handle = handle;


      //utillity function for [ ...remember to fill in ... should we move it to somewhere else...?]
      self.getColumnChangelog = getColumnChangelog;

      // internal functions

      function isUserRow(dataSrc, row, col, evt) {
        var type = row.entity.modType;
        return type && typeof type === "string" && type.trim().toLowerCase() === 'created';
      }

      function handle(dataSrc, row, col, evt) {
        $log.debug("src: " + dataSrc + " col: " + col.name);

        //only "tur" has special cases
        if (dataSrc !== 'tur') {
          return false;
        }

        var isHandled = handlers
          .filter(function (h) {
            return h.willHandle(dataSrc, row, col, evt);
          })
          .reduce(function (acc, h) {
            $log.debug("### handle " + dataSrc + "." + col.name + " by " + h.serviceName + "." + h.methodName);
            h.isHandled = h.method(row, col, evt);
            return acc || h.isHandled;
          }, false);

        return isHandled;
      }


      function getColumnChangelog(rowEntity, columnNames) {
        var colChanges = [];
        if (rowEntity.modChangelog) {
          colChanges = rowEntity.modChangelog
            .filter(function (el) {
              return Object.keys(el.fields).some(function (k) {
                return columnNames.includes(k);
              });
            })
            .reduce(function (chlog, r) {
              chlog.push({
                'fields': r.fields,
                'notes': r.notes,
                'modCreatedBy': r.modCreatedBy,
                'modCreatedAt': r.modCreatedAt
              });
              return chlog;
            }, []);
        }
        return colChanges;
      }

      return self;
    }

  ]);
