angular.module('fiskal').directive('brugerVognDetails', [
  'brugerVognService', 'brugerVognDataService', '$translate', '$log',
  function (brugerVognService, brugerVognDataService, $translate, $log) {

    var module = "details";

    var directiveDefinitionObject = {
      templateUrl: 'brugervognadmin/brugerVognDetails.html',
      restrict: 'E',
      scope: {
        src: "=",
        plexus: "="
      },
      link: postLink
    };

    return directiveDefinitionObject;

    function postLink(scope, iElement, iAttrs) {

      var src = scope.src;

      var saveSelectedIdx = -1;
      var saveSelectedEntity = null;

      scope.isOpen = [];
      var adminModel = [];
      var crudErrorQueue = [];

      var deregs = []; // eventhandlers to deregister

      scope.module = module;
      scope.conf = scope.plexus.config[module][src];
      scope.data = scope.plexus.data[src];
      scope.trans = scope.plexus.translate;

      scope.dsp = scope.plexus.data[src].searchResponse;

      updateSelected(scope.conf.defaultSelected);

      //api

      scope.getBackendCom = brugerVognService.getBackendCom;

      scope.setSelected = updateSelected;
      scope.hide = hide;

      scope.getAdminModel = getAdminModel;
      scope.updateAdminModel = updateAdminModel;

      scope.getForm = getForm;
      scope.getControl = getControl;
      scope.shouldComplain = shouldComplain;
      scope.getErrors = getErrors;

      scope.isEditable = isEditable;
      scope.isRequired = isRequired;

      scope.hasCrudError = hasCrudError;
      scope.getCrudErrorQueue = getCrudErrorQueue;
      scope.clearCrudErrorQueue = clearCrudErrorQueue;

      scope.transSymbEntity = transSymbEntity;
      scope.transSymbEntityWithin = transSymbEntityWithin;
      scope.transSymbProp = transSymbProp;
      scope.transSymbPropWithin = transSymbPropWithin;
      scope.getRelation = getRelation;

      scope.save = save;
      scope.edit = edit;
      scope.erase = erase;
      scope.reset = reset;
      scope.cancel = cancel;
      scope.addAsRelation = addAsRelation;
      scope.isRelation = isRelation;

      deregs.push(watchSearchResponse());
      deregs.push(watchCreateEntity());

      scope.checkboxFields = scope.conf.sortOrder.filter(function (f) {
        return f.type === 'checkbox';
      });

      function watchCreateEntity() {
        return scope.$watch(getCreateEntity, updateCreateEntity);

        function getCreateEntity() {
          return scope.data.createEntity;
        }

        function updateCreateEntity(newVal, oldVal) {
          if (newVal) {
            var newEntity = scope.conf.sortOrder.reduce(function (acc, ent) {
              acc[ent.key] = ent.default;
              return acc;
            }, {});
            saveSelectedIdx = scope.data.selectedIdx;
            //angular.copy to avoid unwanted changes in original
            if (scope.dsp) {
              saveSelectedEntity = angular.copy(scope.dsp);
              scope.dsp.unshift(newEntity);
            } else {
              saveSelectedEntity = undefined;
              scope.dsp = [newEntity];
            }
            scope.isOpen.splice(0);
            adminModel.splice(0);
            scope.data.createEntity = false;
            scope.data.isEditing = true;
            scope.data.isCreating = true;
            updateSelected(0);
          }
        }
      }

      function watchSearchResponse() {
        return scope.$watch(getSearchResponse, updateSearchResponse);

        function getSearchResponse() {
          return scope.plexus.data[src].searchResponse;
        }
        function updateSearchResponse(newVal, oldVal) {
          if (newVal) {
            scope.isOpen.splice(0);
            scope.dsp = scope.plexus.data[src].searchResponse;
            updateSelected(scope.conf.defaultSelected);
          }
        }
      }

      function hasCrudError(_action) {
        crudErrorQueue = crudErrorQueue ? crudErrorQueue : [];
        var _crudErrorQueue = crudErrorQueue.filter(function (err) {
          return err.action === _action;
        });
        return _crudErrorQueue.length > 0;
      }

      function getCrudErrorQueue() {
        crudErrorQueue = crudErrorQueue ? crudErrorQueue : [];
        var _crudErrorQueue = crudErrorQueue.filter(function (err) {
          return err.action === scope.currentBackendAction;
        });
        return _crudErrorQueue;
      }

      function addToCrudErrorQueue(err) {
        crudErrorQueue = crudErrorQueue ? crudErrorQueue : [];
        brugerVognService.parseCrudError(err).forEach(function (rec) {
          crudErrorQueue.push({action: scope.currentBackendAction, error: rec});
        });
      }

      function clearCrudErrorQueue() {
        crudErrorQueue = crudErrorQueue ? crudErrorQueue : [];
        crudErrorQueue = crudErrorQueue.filter(function (err) {
          return err.action !== scope.currentBackendAction;
        });
      }

      function getRelation(entity) {
        return entity === 'bruger' ? 'vogn' : 'bruger';
      }

      function transSymbEntityWithin(entityName) {
        return transSymbEntity(entityName, true);
      }
      function transSymbEntity(entityName, within) {
        var transSymb = entityName;
        if (entityName) {
          entityName = entityName.trim().toLowerCase();
          var type = within ? 'within' : 'terminal';
          var entity = scope.trans.entities[entityName];
          transSymb = entity[type];
        }
        return transSymb;
      }

      function transSymbPropWithin(propName, source) {
        return transSymbProp(propName, source, true);
      }
      function transSymbProp(propName, source, within) {
        var transSymb = propName;
        if (propName) {
          propName = propName.trim().toLowerCase();
          var type = within ? 'within' : 'terminal';
          var _source = source ? source : src;
          var entity = scope.trans.entities[_source];
          var property = entity.properties[propName];
          transSymb = property[type];
        }
        return transSymb;
      }

      function save(event) {
        if (event) {
          event.stopPropagation();
        }
        scope.currentBackendAction = 'save';
        clearCrudErrorQueue();
        if (scope.data.isCreating) {
          scope.dsp[scope.data.selectedIdx].wantsNew = true;
        } else {
          //better safe than sorry!
          delete scope.dsp[scope.data.selectedIdx].wantsNew;
        }
        brugerVognService.setBackendCom(true);
        brugerVognDataService.saveBrugerVogn(src, scope.dsp[scope.data.selectedIdx]).then(
          function success(resp) {
            brugerVognService.setBackendCom(false);
            //angular.copy to trigger watches
            var selectedIdx = saveSelectedIdx;
            if (scope.data.isCreating) {
              if (resp) {
                if (saveSelectedEntity) {
                  saveSelectedEntity.unshift(angular.copy(resp));
                } else {
                  scope.dsp = [angular.copy(resp)];
                }
                selectedIdx = 0;
              }
            } else {
              if (resp) {
                scope.dsp[saveSelectedIdx] = angular.copy(resp);
              }
            }
            scope.isOpen.splice(0);
            updateSelected(selectedIdx);
            clearSaveFlags();
          },
          function error(resp) {
            brugerVognService.setBackendCom(false);
            addToCrudErrorQueue(resp);
          }
        );
      }

      function edit(event) {
        if (event) {
          event.stopPropagation();
        }
        saveSelectedIdx = scope.data.selectedIdx;
        //angular.copy to avoid unwanted changes in original
        saveSelectedEntity = angular.copy(scope.dsp[saveSelectedIdx]);
        scope.data.isEditing = true;
      }

      function erase(event) {
        if (event) {
          event.stopPropagation();
        }
        scope.currentBackendAction = 'delete';
        clearCrudErrorQueue();
        var id = scope.dsp[scope.data.selectedIdx].id;
        brugerVognService.setBackendCom(true);
        brugerVognDataService.deleteBrugerVogn(src, id).then(
          function success(resp) {
            brugerVognService.setBackendCom(false);
            scope.dsp.splice(scope.data.selectedIdx, 1);
            scope.isOpen.splice(0);
            updateSelected(scope.conf.defaultSelected);
            clearSaveFlags();
          },
          function error(resp) {
            brugerVognService.setBackendCom(false);
            addToCrudErrorQueue(resp);
          }
        );
      }

      function cancel(event) {
        if (event) {
          event.stopPropagation();
        }
        reset();
        scope.isOpen.splice(0);
        updateSelected(saveSelectedIdx);
        clearSaveFlags();
      }

      function clearSaveFlags() {
        saveSelectedIdx = -1;
        saveSelectedEntity = null;
        scope.data.isEditing = false;
        scope.data.isCreating = false;
      }

      function reset(event) {
        if (event) {
          event.stopPropagation();
        }
        if (saveSelectedEntity !== null) {
          //angular.copy to trigger watches
          if (angular.isArray(saveSelectedEntity)) {
            scope.dsp = angular.copy(saveSelectedEntity);
          } else {
            scope.dsp[saveSelectedIdx] = angular.copy(saveSelectedEntity);
          }
          scope.data.selectedDetailEntity = scope.dsp[saveSelectedIdx];
        }
        clearCrudErrorQueue();
      }

      function addAsRelation(entity, event) {
        if (event) {
          event.stopPropagation();
        }
        if (!entity) {
          return;
        }
        var relationForeignEntities = getRelationForeignEntities(entity, true);
        if (relationForeignEntities) {
          var entityForeignIdx = entityIdxInForeignEntities(entity, relationForeignEntities);
          if (entityForeignIdx < 0) {
            var newForeignEntity = angular.copy(entity, {});
            //remove any sub-relation
            delete newForeignEntity.roller;
            delete newForeignEntity.brugere;
            delete newForeignEntity.vogne;
            relationForeignEntities.push(newForeignEntity);
          }
        }
      }

      function isRelation(entity) {
        var relation = false;
        if (entity) {
          var relationForeignEntities = getRelationForeignEntities(entity);
          if (relationForeignEntities) {
            var entityForeignIdx = entityIdxInForeignEntities(entity, relationForeignEntities);
            relation = entityForeignIdx > -1;
          }
        }
        return relation;
      }

      function getRelationForeignEntities(entity, createForeignEntityArray) {
        var relationForeignEntities = null;
        if (entity) {
          var relationName = src === 'bruger' ? 'vogn' : 'bruger';
          var relationForeignEntityName = src === 'bruger' ? 'brugere' : 'vogne';
          var relationData = scope.plexus.data[relationName];
          var relationIdx = relationData.selectedIdx;
          var relationEntities = relationData.searchResponse;
          if (relationEntities && angular.isNumber(relationIdx) && relationIdx > -1) {
            if (createForeignEntityArray && !relationEntities[relationIdx][relationForeignEntityName]) {
              relationEntities[relationIdx][relationForeignEntityName] = [];
            }
            relationForeignEntities = relationEntities[relationIdx] ? relationEntities[relationIdx][relationForeignEntityName] : null;
          }
        }
        return relationForeignEntities;
      }

      function entityIdxInForeignEntities(entity, foreignEntities) {
        var idx = -1;
        var foreignEntitiesKeyName = src === 'bruger' ? 'username' : 'vognnr';
        if (entity && foreignEntities) {
          idx = foreignEntities.findIndex(function (r) {
            return r[foreignEntitiesKeyName] === entity[foreignEntitiesKeyName];
          });
        }
        return idx;
      }

      function getAdminModel(entity) {
        var roller = entity ? entity.roller : null;
        if (roller && angular.isArray(roller)) {
          var idx = roller.findIndex(function (r) {
            return r.authority === "ROLE_ADMIN";
          });
          entity.admin = idx > -1;
        }
        return entity ? entity : {admin: false}; // make angular happy ...;
      }

      function updateAdminModel(entity) {
        var admin = entity ? entity.admin : {admin: false}; // make angular happy ...;
        var roller = entity ? entity.roller : null;
        if (admin) {
          roller = roller || [];
          var idx = roller.findIndex(function (r) {
            return r.authority === "ROLE_ADMIN";
          });
          if (idx === -1) {
            roller.unshift({"authority": "ROLE_ADMIN"});
          }
        } else {
          if (roller && angular.isArray(roller)) {
            roller = roller.filter(function (r) {
              return r.authority !== "ROLE_ADMIN";
            });
          }
        }
        if (entity) {
          entity.roller = roller;
        }
      }

      function getErrors(form, key) {
        var control = getControl(form, key);
        var errors = control ? Object.keys(control.$error) : [];
        var errorSymbols = errors.map(function(err){
          return scope.plexus.errormsg[err];
          // return $translate.instant(errormsg[err]);
        });
        return errorSymbols ? errorSymbols[0] : errorSymbols;
      }

      function shouldComplain(form, key) {
        var control = getControl(form, key);
        var willComplain = control && control.$invalid && control.$touched && scope.data.isEditing;
        return willComplain;
      }

      function getForm(form) {
        return form;
      }


      function getControl(form, key) {
        var control = form[key];
        return control;
      }

      function isEditable(key) {
        var editable = false;
        if (scope.data.isEditing && key !== 'username') {
          editable = true;
        } else if (scope.data.isCreating) {
          editable = true;
        }
        return editable;
      }

      function isRequired(prop) {
        var required = !!prop.required;
        if (!scope.data.isCreating && prop.key === 'username') {
          required = false;
        }
        if (scope.data.isCreating && prop.key === 'password') {
          required = true;
        }
        return required;
      }

      function hide(key) {
        var hideMe = scope.conf.hide.includes(key);
        hideMe = hideMe || (key === 'password' && !scope.data.isEditing);
        return hideMe;
      }

      function updateSelected(selectedIdx, event) {
        scope.data.selectedIdx = selectedIdx;
        scope.isOpen[selectedIdx] = true;
        if (event) {
          event.preventDefault();
        }
        scope.data.selectedDetailEntity = scope.dsp ? scope.dsp[selectedIdx] : {};
      }

      //clean up event handlers
      scope.$on('$destroy', function () {
        deregs.forEach(function (dereg) {
          dereg();
        });
      });

    }

  }]);


