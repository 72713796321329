angular.module('fiskal').directive('brugerVognRelation', ['brugerVognService', '$state', '$log',
  function (brugerVognService, $state, $log) {

    var module = "relation";

    var directiveDefinitionObject = {
      templateUrl: 'brugervognadmin/brugerVognRelation.html',
      restrict: 'E',
      scope: {
        src: "=",
        plexus: "="
      },
      link: postLink
    };

    return directiveDefinitionObject;

    function postLink(scope, iElement, iAttrs) {

      var src = scope.src;
      var conf = scope.plexus.config[module][src];
      var data = scope.plexus.data[src];
      var params = $state.params;
      var trans = scope.plexus.translate;


      var deregs = []; // eventhandlers to deregister

      scope.module = module;
      scope.conf = conf;
      scope.trans = trans;
      scope.display = data.selectedDetailEntity ? data.selectedDetailEntity[conf.relation] || [] : [];

      scope.isOpen = [];

      scope.getBackendCom = brugerVognService.getBackendCom;

      scope.setSelected = setSelected;
      scope.searchRelation = searchRelation;
      scope.removeRelation = removeRelation;
      scope.hide = hide;

      scope.transSymbEntity = transSymbEntity;
      scope.transSymbEntityWithin = transSymbEntityWithin;
      scope.transSymbProp = transSymbProp;
      scope.transSymbPropWithin = transSymbPropWithin;
      scope.getRelation = getRelation;

      deregs.push(watchSelectedDetailEntity());

      function watchSelectedDetailEntity() {
        return scope.$watch(getDetailEntity, updateDetailEntity);

        function getDetailEntity() {
          return data.selectedDetailEntity ? data.selectedDetailEntity[conf.relation] : null;
        }

        function updateDetailEntity(newVal, oldVal) {
          scope.display = newVal ? data.selectedDetailEntity[conf.relation] || [] : [];
        }
      }

      function hide(key) {
        return conf.hide.includes(key);
      }


      function setSelected(selectedIdx, event) {
        if (event) {
          event.stopPropagation();
        }
        scope.selectedIdx = selectedIdx;
        scope.isOpen[selectedIdx] = !scope.isOpen[selectedIdx];
        if (event) {
          event.preventDefault();
        }
        // data.selectedDetailEntity = data.searchResponse ? data.searchResponse[selectedIdx] : {};
      }

      function searchRelation(rec) {
        if (event) {
          event.stopPropagation();
        }
        $log.info(angular.toJson(rec));
        var relationSrc = src === 'bruger' ? 'vogn' : 'bruger';
        params[relationSrc][0] = conf.searchKey;
        params[relationSrc][1] = rec[conf.searchKey];
        params[relationSrc][2] = new Date().valueOf();
        $state.go($state.current.name, params);
      }

      function removeRelation(rec, event) {
        if (event) {
          event.stopPropagation();
        }
        var relations = data.selectedDetailEntity[conf.relation];
        data.selectedDetailEntity[conf.relation] = relations.filter(function (rel) {
          return rel.id !== rec.id;
        });
      }

      function getRelation(entity) {
        return entity === 'bruger' ? 'vogn' : 'bruger';
      }

      function transSymbEntityWithin(entityName) {
        return transSymbEntity(entityName, true);
      }
      function transSymbEntity(entityName, within) {
        var transSymb = entityName;
        if (entityName) {
          entityName = entityName.trim().toLowerCase();
          var type = within ? 'within' : 'terminal';
          var entity = trans.entities[entityName];
          transSymb = entity[type];
        }
        return transSymb;
      }

      function transSymbPropWithin(propName, source) {
        return transSymbProp(propName, source, true);
      }
      function transSymbProp(propName, source, within) {
        var transSymb = propName;
        if (propName) {
          propName = propName.trim().toLowerCase();
          var type = within ? 'within' : 'terminal';
          var _source = source ? source : src;
          var entity = trans.entities[_source];
          var property = entity.properties[propName];
          transSymb = property[type];
        }
        return transSymb;
      }


      //clean up event handlers
      scope.$on('$destroy', function () {
        deregs.forEach(function (dereg) {
          dereg();
        });
      });

    }

  }]);


