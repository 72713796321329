"use strict";

angular.module('ffNavbar', ['pascalprecht.translate', 'ffSecurity', 'ffNotify', 'ui.router', 'ngMaterial'])
  .controller('ffNavbarController',
    ['gridService', 'userSettingsDialogService', 'ffNotifyService', 'ffSecurityService', 'ffNavbarService', '$translate', '$uiRouter', '$scope', '$log',
      function (gridService, userSettingsDialogService, ffNotifyService, ffSecurityService, ffNavbarService, $translate, $uiRouter, $scope, $log) {

        var self = this; // return self
        self.user = getUser();

        //store user navbar items in service
        self.userNavs = getUserNavs();
        ffNavbarService.setUserNavStates(self.userNavs);


        //set active navbar item and watch it
        self.curNav = ffNavbarService.getCurrentState();
        $scope.$watch(ffNavbarService.getCurrentState, function (curNav) {
          self.curNav = curNav;
        });
        $scope.$watch(getUser, function(curUsr) {
          self.user = getUser();
        });

        //keep login/out button text current
        self.loginoutTxt = ffSecurityService.isAuthenticated() ? "navbar.logout" : "navbar.login";
        $scope.$watch(
          ffSecurityService.isAuthenticated,
          function (isAuth) {
            self.loginoutTxt = isAuth ? "navbar.logout" : "navbar.login";
            self.userNavs = getUserNavs();
            ffNavbarService.setUserNavStates(self.userNavs);
          }
        );

        self.validate = validate;
        self.isAuthenticated = isAuthenticated;
        self.loginout = loginout;
        self.showUserSettingsDialog = showUserSettingsDialog;

        return self;

        function showUserSettingsDialog(){
          userSettingsDialogService.showUserSettingsDialog();
        }

        //find navbar states/items allowed for current user roles
        function getUserNavs() {
          var navStates = ffNavbarService.getNavigationStates();
          var userRoles = ffNavbarService.getUserRoles();
          return Object.keys(navStates).filter(function (s) {
            return !!navStates[s].roles.find(function (r) {
              return !!r && userRoles.includes(r);
            });
          }).map(function (k) {
            navStates[k].name = k;
            return navStates[k];
          }, {});
        }

        function validate() {
          return ffSecurityService.validate().then(function (resp) {
            var txt = resp ? $translate.instant("navbar.authtoken-valid") : $translate.instant("navbar.authtoken-invalid");
            ffNotifyService.notify(txt);
          }, null);
        }

        function isAuthenticated() {
          return ffSecurityService.isAuthenticated();
        }

        function loginout() {
          if (ffSecurityService.isAuthenticated()) {
            ffSecurityService.logout();
            //todo decouble from gridservice
            //todo put a 'clearData' structure in provider config
            gridService.setUserData(null);
            $uiRouter.stateService.go('home', {reload: true});
            self.user = getUser();
          } else {
            ffSecurityService.login();
            self.user = getUser();
          }
        }

        function getUser() {
          return ffSecurityService.isAuthenticated() ? ffSecurityService.getUserName() : "";
        }

      }])
  .provider('ffNavbarService', function () {

    var me = this;

    me.getUserRolesDef = {};

    me.setGetUserRoles = function (service, fn) {
      me.getUserRolesDef.service = service;
      me.getUserRolesDef.fn = fn;
      return me.getUserRolesDef;
    };

    me.$get = ['$injector', function ($injector) {
      var self = this;
      //BEGIN constructor

      var $uiRouter = $injector.get('$uiRouter');

      //closure vars
      var navigationStates = null;
      var currentState = null;
      var previousState = null;
      var getUserRolesFn = null;
      var userNavStates = [];

      //api
      self.registerNavigationStates = registerNavigationStates;
      self.getNavigationStates = getNavigationStates;

      self.setUserNavStates = setUserNavStates;
      self.getUserNavStates = getUserNavStates;

      self.setCurrentState = setCurrentState;
      self.getCurrentState = getCurrentState;

      self.setPreviousState = setPreviousState;
      self.getPreviousState = getPreviousState;

      self.getUserRoles = getUserRoles;


      //END constructor
      return self;

      //internal functions

      function registerNavigationStates() {
        //get registered states
        var states = $uiRouter.stateRegistry.states;

        //find "base-level" states with navbar data
        //store in ffNavbarService closure
        navigationStates = Object.keys(states).reduce(function (n, k) {
          var st = states[k];
          if (st && isValidBaseLevel(st)) {
            n[k] = st.data.ffNavbar;
          }
          return n;
        }, {});

        function isValidBaseLevel(current) {
          if (!current) {
            //quick exit
            return false;
          }
          var firstValidBaseLevel = getFirstValid(current, current);
          return firstValidBaseLevel && isValidCandidate(firstValidBaseLevel) && firstValidBaseLevel.name === current.name;

          function getFirstValid(candidate, current) {
            //do we have a new candidate or should we stick with the old?
            var myCandidate = isValidCandidate(current) ? current : candidate;
            if (!current.parent || current.parent.name === "") {
              //at this end of chain
              return myCandidate;
            } else {
              //explore the parent chain further
              return getFirstValid(myCandidate, current.parent);
            }
          }

          function isValidCandidate(state) {
            return !!state && !state.abstract && state.data && state.data.ffNavbar;
          }
        }

      }


      function getNavigationStates() {
        return navigationStates;
      }

      function setUserNavStates(userNav) {
        userNavStates = userNav;
        return userNavStates;
      }

      function getUserNavStates() {
        return userNavStates;
      }

      function setCurrentState(curState) {
        currentState = curState;
        return currentState;
      }

      function getCurrentState() {
        return currentState;
      }

      function setPreviousState(prevState) {
        previousState = prevState;
        return previousState;
      }

      function getPreviousState() {
        return previousState;
      }

      function getUserRoles() {
        if (!getUserRolesFn) {
          getUserRolesFn = $injector.get(me.getUserRolesDef.service)[me.getUserRolesDef.fn];
        }
        return getUserRolesFn();
      }

    }];
  })
  .run(['ffNavbarService', '$uiRouter', '$transitions', '$trace', '$log',
    function (ffNavbarService, $uiRouter, $transitions, $trace, $log) {

      //log state transitions
      $trace.enable('TRANSITION');

      // register base-level states with navbar data
      ffNavbarService.registerNavigationStates();

      //register handler to update navigation bar to show current state
      //register initial state
      ffNavbarService.setCurrentState($uiRouter.stateService.current.name);
      ffNavbarService.setPreviousState("");
      $transitions.onStart({}, function (trans) {
        //update navigation
        if (trans.to().name) {
          ffNavbarService.setCurrentState(trans.to().name);
        }
        if (trans.from().name) {
          ffNavbarService.setPreviousState(trans.from().name);
        }
      });

    }])
;
