'use strict';

angular.module('ffSecurity').service('loginDialogService', ['ffSecurityService', '$mdDialog', '$document', '$log', function (ffSecurityService, $mdDialog, $document, $log) {

  var self = this; // return self;

  self.loginPrompt = loginPrompt;
  self.showLoginDialog = showLoginDialog;

  function loginPrompt(deferedLogin, userName, message) {

    showLoginDialog(userName ? userName : "", message ? message : "").then(
      function success(resp) {
        //user entered credentials
        deferedLogin.resolve(resp);
      },
      function error() {
        //just ignore
      });
  }

  function showLoginDialog(userName, message) {

    message = message ? message : "";

    var title = "security.login.title";

    var fields = [
      {
        name: 'userName',
        label: "security.login.username",
        type: 'text',
        value: userName || "",
        tabindex: 1
      },
      {
        name: 'password',
        label: "security.login.password",
        type: 'password',
        value: "",
        tabindex: 2
      }
    ];

    var dlgConfig = {
      controller: dialogController,
      controllerAs: 'dlg',
      bindToController: true,
      locals: {
        title: title,
        fields: fields,
        message: message
      },
      templateUrl: 'security/loginDialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      disableParentScroll: false,
      fullscreen: false
    };


    // internal functions

    dialogController['$inject'] = ['$scope', '$mdDialog'];
    function dialogController($scope, $mdDialog) {
      var dlg = this;

      dlg.login = function () {
        var response = {};
        dlg.fields.forEach(function (f) {
          response[f.name] = f.value;
        });
        $mdDialog.hide(response);
      };

      dlg.cancel = function () {
        $mdDialog.hide();
      };
    }

    return $mdDialog.show(dlgConfig).then(
      function success(response) {
        $log.debug("in loginDialog show: got response from dialog");
        return response;
      },
      function error() {
        // just ignore
      }
    );

  }

  return self;
}
]);
