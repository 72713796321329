"use strict";

angular.module('fiskal',
  ['ffNotify', 'ffSecurity', 'ffNavbar', 'pascalprecht.translate', 'ngLocale',
    'ui.bootstrap', 'ngMaterial', 'ngCookies', 'ngMessages', 'ngAnimate', 'ui.router', 'ui.grid', 'ui.grid.cellNav',
    'ui.grid.resizeColumns', 'ui.grid.autoResize', 'ui.grid.emptyBaseLayer']
)
  .config(['$mdThemingProvider', '$mdInkRippleProvider', '$mdAriaProvider', '$mdIconProvider',
    function ($mdThemingProvider, $mdInkRippleProvider, $mdAriaProvider) {

      $mdThemingProvider.definePalette('frogne-blue-003c71', {
        '50': 'e0e8ee',
        '100': 'b3c5d4',
        '200': '809eb8',
        '300': '4d779c',
        '400': '265986',
        '500': '003c71',
        '600': '003669',
        '700': '002e5e',
        '800': '002754',
        '900': '001a42',
        'A100': '779dff',
        'A200': '4478ff',
        'A400': '1154ff',
        'A700': '0045f6',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': [
          '50',
          '100',
          '200',
          'A100'
        ],
        'contrastLightColors': [
          '300',
          '400',
          '500',
          '600',
          '700',
          '800',
          '900',
          'A200',
          'A400',
          'A700'
        ]
      });

      $mdThemingProvider.theme('default')
        .primaryPalette('frogne-blue-003c71')
        .accentPalette('frogne-blue-003c71')
        .warnPalette('deep-orange');

      $mdInkRippleProvider.disableInkRipple();

      //SHUT THE FUCK UP ABOUT ARIA!!!!!!!
      $mdAriaProvider.disableWarnings();

    }])
  .config(['$translateProvider', 'installationvariants', function ($translateProvider, installationvariants) {

    $translateProvider.useStaticFilesLoader({
      prefix: 'i18n/locale-',
      suffix: '.json'
    });

    $translateProvider.registerAvailableLanguageKeys(['de_DE', 'da_DK']);
    $translateProvider.forceAsyncReload(true);
    $translateProvider.useSanitizeValueStrategy('escape');
    $translateProvider.keepContent(true);
    $translateProvider.useLocalStorage();
    $translateProvider.useLoaderCache(true);
    $translateProvider.use(installationvariants.locale);

  }])
  .config(
    ['$httpProvider', function ($httpProvider) {
      $httpProvider.interceptors.push('ffSecurityInterceptor');
    }])
  .config(
    ['ffSecurityServiceProvider','installationvariants', function (ffSecurityServiceProvider, installationvariants) {

      ffSecurityServiceProvider.setAuth({resourcePrefix: installationvariants.resourcePrefix.replace(/[^\/]*\/$/, "api/")});
      ffSecurityServiceProvider.setUseSessionStorage(true);
//      ffSecurityServiceProvider.setCharlie(true);

    }])  .config(
    ['ffNavbarServiceProvider', function (ffNavbarServiceProvider) {

      ffNavbarServiceProvider.setGetUserRoles('ffSecurityService', 'getUserRoles');

    }])
  .run(['$rootScope', '$transitions', '$trace',
    function ($rootScope, $transitions, $trace) {

      //TODO Code here is a modified version of what is present in the rc-navbarmodule
      //We do this because of the upgrade of ui-route to v1. This code should be removed
      //again when/if the navbarmodule becomes integrated in the fiskal project

      //log state transitions
      $trace.enable('TRANSITION');

      //register handler to update navigation bar to show current state
      //register initial state
      $rootScope.curNav = 'home';
      $rootScope.previousState = '';

      $transitions.onStart({}, function (trans) {
        //update navigation
        if (trans.to().name) {
          $rootScope.curNav = trans.to().name;
        }
        if (trans.from().name) {
          $rootScope.previousState = trans.from().name;
        }
      });

    }
  ]);

