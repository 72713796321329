angular.module('fiskal').config(['defaultsearchparams', 'vagtsearchparams', 'tursearchparams', '$stateProvider', '$urlServiceProvider', '$urlRouterProvider', '$locationProvider',
  function (defaultsearchparams, vagtsearchparams, tursearchparams, $stateProvider, $urlServiceProvider, $urlRouterProvider, $locationProvider) {

    $locationProvider.html5Mode(false).hashPrefix('');
    $urlServiceProvider.rules.otherwise('/');

    var vagtParams = angular.merge({}, defaultsearchparams, vagtsearchparams);
    var turParams = angular.merge({}, defaultsearchparams, tursearchparams);

    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'home.html',
        data: {
 //         ffNavbar: {
 //           display: 'navbar.state.home.display',
 //           roles: ['ROLE_ADMIN', 'ROLE_VOGNMAND'],
 //           order: 0
 //         }
        }
      })
      .state('gridContainer', {
        abstract: true,
        templateUrl: 'grid/gridContainer.html',
        controller: 'gridContainerController',
        controllerAs: 'ctrl'
      })
      .state('vagt', {
        parent: 'gridContainer',
        url: '^/grid/vagt?' + Object.keys(vagtParams).join("&"),
        views: {
          search: {
            templateUrl: 'grid/search/gridSearch.html',
            controller: 'gridSearchController',
            controllerAs: 'searchCtrl'
          },
          grid: {
            templateUrl: 'grid/grid.html',
            controller: 'gridController',
            controllerAs: 'gridCtrl'
          }
        },
        params: vagtParams,
        data: {
          ffNavbar: {
            display: 'navbar.state.vagt.display',
            roles: ['ROLE_ADMIN', 'ROLE_VOGNMAND'],
            order: 3
          }
        }
      })
      .state('tur', {
        parent: 'gridContainer',
        url: '^/grid/tur?' + Object.keys(turParams).join("&"),
        views: {
          search: {
            templateUrl: 'grid/search/gridSearch.html',
            controller: 'gridSearchController',
            controllerAs: 'searchCtrl'
          },
          grid: {
            templateUrl: 'grid/grid.html',
            controller: 'gridController',
            controllerAs: 'gridCtrl'
          }
        },
        params: turParams,
        data: {
          ffNavbar: {
            display: 'navbar.state.tur.display',
            roles: ['ROLE_ADMIN', 'ROLE_VOGNMAND'],
            order: 4
          }
        }
      });

  }]);
