'use strict';

/*global ffFiskalPortalInstallationVariants*/
angular.module('fiskal')
  .constant('installationvariants',
    (function(){
      var defaults = {
        resourcePrefix: "/api/",
        locale: "de_DE"
      };

      var vars = ffFiskalPortalInstallationVariants;
      if (vars) {
        Object.keys(defaults).forEach(function(k) {
          vars[k] = vars[k] || defaults[k];
        });
      } else {
        vars = defaults;
      }
      return vars;
    })()
  );

