'use strict';

angular.module('fiskal').service('brugerVognService', [
  function () {

    var self = this; // return self;

    //closure vars
    var _backendCom = false;

    var crudParsers = defineCrudParsers();
    var entityValidationClasifiers = defineEntityValidationClasifiers();

    self.getBackendCom = getBackendCom;
    self.setBackendCom = setBackendCom;
    self.parseCrudError = parseCrudError;

    return self;

    function getBackendCom() {
      return _backendCom;
    }

    function setBackendCom(backendCom) {
      _backendCom = backendCom;
      return _backendCom;
    }

    function parseCrudError(errObj) {
      crudParsers.find(function (p) {
        var willHandle = p.willHandle(errObj);
        if (willHandle) {
          errObj = p.parse(errObj);
        }
        return willHandle;
      });
      errObj = angular.isArray(errObj) ? errObj : [errObj];
      return errObj;
    }

    function defineCrudParsers() {
      var crudParsers = [

        {
          name: "entityValidation",

          willHandle: function (errObj) {
            var handles = errObj && errObj.status === 422;
            handles = handles && errObj.data && errObj.data.errors && angular.isArray(errObj.data.errors);
            return handles;
          },

          parse: function (errObj) {
            var self = this;
            var parseObj = errObj.data.errors.map(function (err) {
              //find and return first matching clasifier for each error
              var parsed = Object.keys(entityValidationClasifiers).reduce(function (acc, k) {
                if (!acc) {
                  var m = err.message.match(entityValidationClasifiers[k].regex);
                  if (m) {
                    var props = entityValidationClasifiers[k].properties;
                    acc = {breach: k, entity: m[props.entity], key: m[props.key], value: m[props.value]};
                  }
                }
                return acc;
              }, null);
              parsed.name = self.name;
              return parsed;
            });
            return parseObj;
          }
        },

        {
          name: "default",

          willHandle: function (errObj) {
            return true;
          },

          parse: function (errObj) {
            var self = this;
            // we assume some kind of property based format
            var parseObj = {};
            //first format is local error 'cannot post etc', second is remote error
            errObj = angular.isString(errObj.data) ? errObj : errObj.data;
            if (errObj) {
              parseObj = Object.keys(errObj).reduce(function (acc, k) {
                angular.isObject(errObj[k]);
                acc[k] = angular.isObject(errObj[k]) ? angular.toJson(errObj[k]) : errObj[k].toString();
                acc[k] = acc[k].substr(0, 75);
                return acc;
              }, parseObj);
            }
            parseObj.name = self.name;
            return parseObj;
          }
        }

      ];
      return crudParsers;
    }

    function defineEntityValidationClasifiers() {
      var entityValidationClasifiers = {
        unique: {
          regex: /property\s+\[([^\]]*)\].*?\s+class\s+\[class\s+(?:[^.]+\.)*([^\]]*?)\].*?\s+value\s+\[([^\]]*)\].*?\s+must\s+be\s+(unique)/i,
          properties: {key: 1, entity: 2, value: 3, constraint: 4}
        }
      };
      return entityValidationClasifiers;
    }

  }]);


