angular.module('fiskal').service('userRowDialogService', ['gridService', 'gridDataService', 'uiGridConstants', '$translate', '$mdDialog', '$q', '$injector', '$log',
  function (gridService, gridDataService, uiGridConstants, $translate, $mdDialog, $q, $injector, $log) {
    var self = this; // return self;

    var userRowFields = defineUserRowFields();
    userRowFields._isInitialized = false;

    var formFieldKeys = [];
    Object.keys(userRowFields).reduce(function (acc, k) {
      if (userRowFields[k].show && (!userRowFields[k].readonly || userRowFields[k].dropdown)) {
        acc[k] = userRowFields[k];
        formFieldKeys.push(k);
      }
      return acc;
    }, {});

    function defineUserRowFields() {

      var fields = {
        turId: {
          type: "integer",
          show: false,
          required: true,
          readonly: true
        },
        modId: {
          type: "integer",
          show: false,
          required: true,
          readonly: true
        },
        modOrigId: {
          type: "integer",
          show: false,
          required: true,
          readonly: true
        },
        selskabsnr: {
          type: "integer",
          show: false,
          required: true,
          readonly: true
        },
        vognnr: {
          type: "integer",
          show: true,
          required: true,
          readonly: true
        },
        vagtnr: {
          type: "integer",
          show: true,
          required: true,
          readonly: true
        },
        turnr: {
          type: "text",
          show: true,
          required: true,
          readonly: true
        },
        chauffoernr: {
          type: "integer",
          show: true,
          required: true,
          readonly: true
        },
        startTs: {
          type: "datetime",
          show: true,
          required: true,
          readonly: false,
          validators: {mustchange: mustchange('startTs')},
          errors: {
            pattern: "grid.diaglog.edit.userrow.datatime.pattern",
            required: "grid.diaglog.edit.userrow.required",
            mustchange: "grid.diaglog.edit.userrow.mustchange"
          }
        },
        slutTs: {
          type: "datetime",
          show: true,
          required: true,
          readonly: false,
          validators: {mustchange: mustchange('slutTs')},
          errors: {
            pattern: "grid.diaglog.edit.userrow.datatime.pattern",
            required: "grid.diaglog.edit.userrow.required",
            mustchange: "grid.diaglog.edit.userrow.mustchange"
          }
        },
        distanceTotal: {
          type: "float",
          show: true,
          required: false,
          readonly: false,
          errors: {
            pattern: "grid.diaglog.edit.userrow.float.pattern"
          },
          clear: true
        },
        betalingsart: {
          type: "text",
          show: true,
          required: true,
          readonly: false,
          errors: {
            required: "grid.diaglog.edit.userrow.required.select"
          },
          dropdown: {
            basekey: "grid.diaglog.edit.userrow.betalingsart.value.",
            translatedrange: {
              "Kredit": {value: "kredit"},
              "Bar": {value: "kontant"},
              "Konto": {value: "konto"}
            }
          },
          placeholder: "grid.diaglog.edit.userrow.betalingsart.value.placeholder",
          clear: true
        },
        momsprocent: {
          type: "float",
          show: true,
          required: true,
          readonly: false,
          errors: {
            required: "grid.diaglog.edit.userrow.required"
          },
          dropdown: {
            basekey: "grid.diaglog.edit.userrow.momsprocent.value.",
            translatedrange: {
              "7000": {value: 7000},
              "19000": {value: 19000}
            }
          },
          placeholder: $translate.instant("grid.diaglog.edit.userrow.momsprocent.value.placeholder")

        },
        betalingIalt: {
          type: "float",
          show: true,
          required: true,
          errors: {
            pattern: "grid.diaglog.edit.userrow.float.pattern",
            required: "grid.diaglog.edit.userrow.required"
          },
          readonly: false,
          clear: false
        }

      };

      function mustchange(field) {
        return function (modelValue, viewValue) {
          if (self.dlg.saveUserRowFields.turId.value < 0) {
            return true; // not new - dont care
          }
          //new => must change
          var control = self.dlg.$scope.dlgForm[field];
          var val = control.$parsers.reduce(function (acc, f) {
            acc = f(acc);
            return acc;
          }, viewValue);
          var saveVal = control.$formatters.reduce(function (acc, f) {
            acc = f(acc);
            return acc;
          }, self.dlg.saveUserRowFields[field].value);
          saveVal = control.$parsers.reduce(function (acc, f) {
            acc = f(acc);
            return acc;
          }, saveVal);
          return saveVal !== val;
        };
      }

      return fields;
    }

    self.showUserRowDialog = function (row, col, evt) {

      if (!angular.isDefined(row.entity[col.name]) || row.entity[col.name] === null) {
        return true; // is handled => true -- do not edit when turnr is not present (i.e. the 'tur' is a break)
      }

      var shouldCreate = row.entity.turId > 0;

      if (!userRowFields._isInitialized) {
        userRowFields = Object.keys(userRowFields).reduce(function (acc, k) {
          var coldef = row.grid.getColDef(k);
          if (coldef) {
            acc[k].coldef = coldef;
            acc[k].label = coldef.displayNameEdit || coldef.displayName;
          }
          return acc;
        }, userRowFields);
        userRowFields._isInitialized = true;
      }

      var dlgConfig = configUserRow(dialogController);

      showDialog(dlgConfig, $mdDialog);

      return true;

      function configUserRow(dlgController) {
        var dlg = {
          controller: dlgController,
          controllerAs: 'dlg',
          bindToController: true,
          locals: {
            column: col,
            row: row,
            userRowFields: userRowFields,
            formFieldKeys: formFieldKeys,
            $translate: $translate
          },
          templateUrl: 'grid/cellaction/userRowDialog.html',
          parent: angular.element(document.body),
          targetEvent: evt,
          clickOutsideToClose: false,
          disableParentScroll: false,
          fullscreen: false,
          onComplete: onComplete
        };
        return dlg;
      }

      function showDialog(dlgConfig, mdDialog) {
        mdDialog.show(dlgConfig).then(
          function (response) {
            $log.debug("in userRow showDialog: resolved promise");

            var gridData = row.grid.options.data;

            //for "saving"-visualization
            var visibleCols = row.grid.columns
              .filter(function (col) {
                return col.colDef.visible;
              })
              .map(function (col) {
                return col.colDef;
              });

            switch (response.action) {

              case "save":
                // "action" is not needed for rest of update process: easiest just to get rid of it now
                delete response.action;
                var updIdx;
                if (response.modOrigId) {
                  //update
                  updIdx = gridData.findIndex(function (r) {
                    return r.$$hashKey === row.entity.$$hashKey;
                  });
                } else {
                  //new
                  updIdx = -1;
                }
                //start save visualization
                visibleCols.forEach(function (coldef) {
                  gridService.setSaving('tur', row, coldef, true);
                });

                gridDataService.saveTurRow(response).then(
                  function (response) {
                    if (updIdx !== -1) {
                      //update
                      //deep copy to ensure update of modified row
                      gridData[updIdx] = angular.merge(gridData[updIdx], response);
                    } else {
                      //new user row
                      updIdx = gridData.push(response) - 1;
                    }
                    //stop save visualizartion
                    visibleCols.forEach(function (coldef) {
                      gridService.setSaving('tur', row, coldef, false);
                    });
                  },
                  function (response) {
                    //todo complain
                  }
                );
                break;
              case "delete":
                var delIdx = gridData.findIndex(function (r) {
                  return r.$$hashKey === row.entity.$$hashKey;
                });
                //start save visualization
                visibleCols.forEach(function (coldef) {
                  gridService.setSaving('tur', row, coldef, true);
                });
                gridDataService.deleteTurRow(row.entity).then(
                  function (response) {
                    //stop save visualizartion
                    visibleCols.forEach(function (coldef) {
                      gridService.setSaving('tur', row, coldef, false);
                    });
                    var deleted = (gridData.splice(delIdx, 1)[0]);
                    $log.debug("row deleted: idx = " + delIdx + " $$hashKey = " + deleted.$$hashKey);
                  },
                  function (response) {
                    //todo complain
                  }
                );
                break;
              default:
                break;
            }
            row.grid.api.core.refreshRows();
          }, null);
      }

      function onComplete(scope, element) {
        scope.dlgForm.$setPristine();
        scope.dlgForm.$setUntouched();
      }

      function dialogController($scope, $mdDialog, $log) {
        dialogController['$inject'] = ['$scope', '$mdDialog', '$log'];

        var dlg = this;
        self.dlg = dlg;

        //closure vars: private variables

        var inputBacking = {
          modelinit: {},
          readyDefered: {},
          all: {}
        };

        Object.keys(userRowFields)
          .filter(function (k) {
            var reject = k.startsWith("_");
            reject = reject || !userRowFields[k].validators;
            return !reject;
          })
          .forEach(function (k) {
            inputBacking.readyDefered[k] = $q.defer();
            inputBacking.modelinit[k] = watchInput(k);
            inputBacking.all[k] = $q.all([inputBacking.readyDefered[k].promise]);
            inputBacking.all[k].then(function () {
              var control = $scope.dlgForm[k];
              var field = userRowFields[k];
              control.$validators = angular.merge(control.$validators, field.validators);
              control.$validate(); // Vi vil have validering kørt uden at bruger har rørt værdier.
            });
          });

        function watchInput(field) {

          return $scope.$watch(getInput, configInput);

          function getInput(scope) {
            return !!scope.dlgForm && !!scope.dlgForm[field];
          }

          function configInput(newVal, oldVal) {
            $log.debug("Input ready: " + oldVal + " --> " + newVal);
            if (newVal === true && $scope.dlgForm && $scope.dlgForm[field]) {
              inputBacking.modelinit[field](); //dereg watcher for field
              inputBacking.readyDefered[field].resolve();
            }
          }

        }


        userRowFields = Object.keys(userRowFields)
          .filter(function (k) {
            return !k.startsWith("_");
          })
          .map(function (k) {
            userRowFields[k].value = null; //reset
            return k;
          })
          .filter(function (k) {
            return !(shouldCreate && userRowFields[k].clear);
          })
          .reduce(function (acc, k) {
            acc[k].value = row.entity[k];
            return acc;
          }, userRowFields);

        var saveUserRowFields = Object.keys(userRowFields).reduce(function (acc, k) {
          acc[k] = {value: userRowFields[k].value};
          return acc;
        }, {});
        var saveNote = dlg.note;

        //create row --> get next turnr
        if (shouldCreate) {
          var turnr = dlg.userRowFields.turnr;
          turnr.value = "...";
          gridDataService.getNextTurnr(row.entity.turId).then(function success(response) {
            turnr.value = response;
            saveUserRowFields.turnr.value = response;
          });
        }

        var grid = dlg.row.grid;
        var rowEntity = dlg.row.entity;

        dlg.noteLabel = grid.getColDef('modChangelog').displayName;
        dlg.note = "";

        dlg.saveUserRowFields = saveUserRowFields;
        dlg.saveNote = saveNote;

        //expose api
        dlg.getDropdownValue = gridService.getDropdownValue;
        dlg.getForm = _getForm;

        dlg.hide = _hide;
        dlg.reset = _reset;
        dlg.delete = _delete;
        dlg.cancel = _cancel;
        dlg.save = _save;
        dlg.mayDelete = _mayDelete;
        dlg.maySave = _maySave;


        //internal functions

        function _maySave() {
          var maysave = formFieldKeys.every(function (k) {
            return $scope.dlgForm[k] ? $scope.dlgForm[k].$valid : false;
          });
          maysave = maysave && formFieldKeys.some(function (k) {
              return $scope.dlgForm[k] ? $scope.dlgForm[k].$dirty : false;
            });
          return maysave;
        }

        function _mayDelete() {
          return dlg.row.entity.turId < 0;
        }

        function _getForm() {
          return $scope.dlgForm;
        }

        function _hide() {
          $mdDialog.hide();
        }

        function _reset() {
          Object.keys(userRowFields).forEach(function (k) {
            userRowFields[k].value = saveUserRowFields[k].value;
          });
          dlg.note = saveNote;
          $scope.dlgForm.$setPristine();
          $scope.dlgForm.$setUntouched();
        }

        function _delete() {
          if (rowEntity.turId < 0) {
            var response = {};
            response.action = "delete";
            $mdDialog.hide(response);
          }
        }

        function _cancel() {
          $mdDialog.cancel();
        }

        function _save() {
          var response = {};
          Object.keys(userRowFields)
            .filter(function (k) {
              return !['internal', '_isInitialized'].includes(k);
            })
            .forEach(function (k) {
              var fld = userRowFields[k];
              response[k] = fld.value;
            });
          response.action = "save";
          response.modType = "created";
          response.notes = dlg.note;
          $mdDialog.hide(response);
        }
      }

    };

    return self;


  }
]);
