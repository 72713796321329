'use strict';

angular.module('fiskal').filter('duration', ['$log',
  function ($log) {
    //pls see format docs in moment-duration-format
    return function (input, format, scale) {
      format = format || 'HH.mm';
      scale = scale || 1;
      var duration = null;
      if (typeof input === 'number') {
        duration = moment.duration(input * scale);
      } else if (typeof input === 'object' && moment.isDuration(input)) {
        duration = input;
      }
      if (duration) {
        return duration.format(format, {trim: false});
      }
      return input;
    };
  }]);
