'use strict';

angular.module('fiskal').service('brugerVognDataService', ['installationvariants', '$http', '$q', '$log',
  function (installationvariants, $http, $q, $log) {

    var self = this; // return self;

    //closure vars
    var searchUrls = {
      'bruger': installationvariants.resourcePrefix + "bruger/search",
      'vogn': installationvariants.resourcePrefix + "vogn/search"
    };

    var saveUrls = {
      'bruger': installationvariants.resourcePrefix + "bruger",
      'vogn': installationvariants.resourcePrefix + "vogn"
    };

    var deleteUrls = {
      'bruger': installationvariants.resourcePrefix + "bruger/",
      'vogn': installationvariants.resourcePrefix + "vogn/"
    };

    self.searchBrugerVogn = searchBrugerVogn;
    self.saveBrugerVogn = saveBrugerVogn;
    self.deleteBrugerVogn = deleteBrugerVogn;

    return self;

    function searchBrugerVogn(dataSrc, query) {
      return $http.post(searchUrls[dataSrc], query).then(
        function success(resp) {
          return resp.data;
        },
        function error(resp) {
          $log.error("searchBrugerVogn(" + "source: " + dataSrc + ", query: " + angular.toJson(query) + "): HTTP status: "
            + resp.status + " status text: " + resp.statusText);
          return $q.reject(resp);
        });
    }

    function saveBrugerVogn(dataSrc, data) {
      var wantsNew = data.wantsNew;
      delete data.wantsNew;
      var url = wantsNew ? saveUrls[dataSrc] + '?wantsNew=true' : saveUrls[dataSrc];
      return $http.post(url, data).then(
        function success(resp) {
          return resp.data;
        },
        function error(resp) {
          $log.error("saveBrugerVogn(" + "source: " + dataSrc + ", data: " + angular.toJson(data) + "): HTTP status: "
            + resp.status + " status text: " + resp.statusText);
          return $q.reject(resp);
        });
    }

    function deleteBrugerVogn(dataSrc, id) {
      return $http.delete(deleteUrls[dataSrc] + id).then(
        function success(resp) {
          return resp.data;
        },
        function error(resp) {
          $log.error("deleteBrugerVogn(" + "source: " + dataSrc + ", id: " + id + "): HTTP status: "
            + resp.status + " status text: " + resp.statusText);
          return $q.reject(resp);
        });
    }

  }]);


