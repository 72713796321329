angular.module('fiskal').component('datetimeDialog', function () {

  //definition returned at end of function
  var datetimeDefinition = {
    require: {},
    template: ' \
        <div style="position: relative;"> \
          <label for="{{$ctrl.name}}" ng-class="{\'ff-dialog-readonly\': $ctrl.field.readonly}">\
            <translate>{{$ctrl.field.label}}</translate> \
          </label> \
        </div> \
        <div style="position: relative;"> \
          <span ng-if="$ctrl.field.required && !$ctrl.field.readonly" class="ff-dialog-required">*</span> \
          <input \
               type="text" \
               name="{{$ctrl.name}}" \
               ng-required="$ctrl.field.required" \
               ng-readonly="$ctrl.field.readonly" \
               ng-pattern="$ctrl.datetimeInputPattern" \
               ng-model="$ctrl.field.value" \
               ng-model-options="{ updateOn: \'blur\' }"\
               ng-blur="$ctrl.updateModel()" \
               placeholder=" "> \
        </div> \
      ',
    // or function(tElement, tAttrs) { ... },
    // or templateUrl: 'directive.html',
    bindings: {
      form: "<",
      name: "<",
      field: "<"
    },
    controller: ['$scope', '$element', '$attrs', '$transclude', '$injector', ctrl]
//      link: postLink
  };


  //controller
  function ctrl($scope, $element, $attrs, $transclude, $injector) {
    var self = this; // return self;

    var $log = $injector.get('$log');
    var gridService = $injector.get('gridService');

    var deregs = [];

    // DATETIME FORMAT DEPENDENCY
    self.datetimeInputPattern = '\\d{2}[.]\\d{2}[.]\\d{4} \\d{2}[:]\\d{2}';

    self.$onInit = function () {
      $log.debug("$onInit: " + self.name);
    };

    self.$postLink = function () {
      $log.debug("$postLink: " + self.name);
      var control = self.form[self.name];
      if (control) {
        control.$formatters[0] = function (value) {
          return gridService.formatAsDisplayValue(value, self.field.coldef);
        };
        control.$parsers.push(function (displayValue) {
          return gridService.parseGridDisplayedValue(displayValue, self.field.coldef) || "";
        });
      }
    };

    self.$onDestroy = function () {
      deregs.forEach(function (f) {
        f();
      });
    };

    self.updateModel = function () {
      var control = self.form[self.name];
      control.$commitViewValue();
      control.$setTouched();
      control.$validate();
    };
  }

  return datetimeDefinition;

}());
