'use strict';

angular.module('fiskal').service('notifyService', ['$mdToast', '$log',
  function ($mdToast, $log) {
    //BEGIN constructor

    var self = this; // return self;

    //closure vars
    var notification= $mdToast.simple().capsule(true).position("bottom right").hideDelay(4000) ;

    //api
    self.notify = notify;

    //internal functions
    function notify(text) {
      notification.textContent(text);
      $mdToast.show(notification);
    }


    //END constructor
    return self;
  }]);
