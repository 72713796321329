"use strict";

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').controller('gridSearchController',
  ['ffNotifyService', 'ffSecurityService', 'gridService', 'gridDataService', 'gridConfigService',
    'gridSearchConfigService', 'mdDateLocaleService', '$uiRouter', '$scope', '$q', '$timeout', '$log',
    function (ffNotifyService, ffSecurityService, gridService, gridDataService, gridConfigService,
              gridSearchConfigService, mdDateLocaleService, $uiRouter, $scope, $q, $timeout, $log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR
      $log.debug("Entered gridSearchController at " + moment().format("YYYY-MM-DD HH.mm.ss.SSS"));

      //closure vars: private variables
      var deregs = [];
      var inputBacking = {
        modelinit: {},
        readyDefered: {},
        all: {}
      };

      var userDataValues;

      // api: public methods and fields

      self.fields = gridSearchConfigService.getSearchFields($uiRouter.stateService.current.name);
      self.showDetail = gridService.showDetail;
      self.toggleDetails = toggleDetails;
      self.getSearchAnimation = getSearchAnimation;
      self.getDropdownValue = gridService.getDropdownValue;
      self.submitSearch = submitSearch;
      self.submitDownload = submitDownload;
      self.updateModel = updateModel;

      //initialization code
      mdDateLocaleService.initLocale();
      var dropDownFields = Object.keys(self.fields).filter(function (k) {
        return !!self.fields[k].dropdown;
      });

      deregs.push(watchFormDirty());
      deregs.push(watchParams());

      gridService.showDetail = false;

      var deferedUserDataValues = $q.defer();
      userDataValues = gridService.getUserDataValues();
      if (userDataValues) {
        deferedUserDataValues.resolve(userDataValues);
      } else {
        //clear search fields settings escpecialy dropdowns
        self.fields = gridSearchConfigService.resetSearchFields($uiRouter.stateService.current.name);
        gridDataService.queryData({search:[{field:"username", op:"eq", args:[ffSecurityService.getUserName()]}]}, {}, 'user').then(
          function success(resp) {
            deferedUserDataValues.resolve(gridService.setUserData(resp));
          }, function (resp) {
            deferedUserDataValues.reject(resp);
          });
      }

      var dropdownPromise = deferedUserDataValues.promise.then(
        function (userDataValues) {
          if (userDataValues) {
            Object.keys(userDataValues).filter(function (key) {
              return angular.isDefined(self.fields[key]);
            }).forEach(function (key) {
              var dropdown = self.fields[key].dropdown;
              if (dropdown) {
                gridService.getUserDataValues(key)
                  .filter(function (el) {
                    return !dropdown.range.includes(el);
                  })
                  .forEach(function (el) {
                    dropdown.range.push(el);
                    dropdown.translatedrange[el] = {value: el, istranslated: false};
                  });
              }
            });
          }
        },
        function (resp) {
          ffNotifyService.notify(resp);
        });


      dropDownFields.forEach(function (field) {
        inputBacking.readyDefered[field] = $q.defer();
        inputBacking.modelinit[field] = watchDropDrown(field);
        inputBacking.all[field] = $q.all([dropdownPromise, inputBacking.readyDefered[field].promise]);
        inputBacking.all[field].then(function () {
          var inputName = "search-" + field;
          if ($scope.gridSearch && $scope.gridSearch[inputName]) {
            var control = $scope.gridSearch[inputName];
            control.$modelValue = self.params[field];
            control.$viewValue = control.$formatters.reduce(function (acc, f) {
              acc = f(acc);
              return acc;
            }, control.$modelValue);
            control.$render();
          }
        });
      });


      $scope.$on('$destroy', function () {
        deregs.forEach(function (f) {
          f();
        });
      });

      //END CONSTRUCTOR
      return self;

      //internal functions: private methods


      function updateModel(k, raw) {
        self.params[k] = raw;
      }

      function getSearchAnimation() {
        return {'ff-grid-search-icon-animation': gridService.getSearching()};
      }


      function toggleDetails() {
        gridService.showDetail = !gridService.showDetail;
        self.showDetail = gridService.showDetail;
      }

      function watchDropDrown(field) {
        var inputName = "search-" + field;
        return $scope.$watch(getInput, configInput);

        function getInput(scope) {
          return !!scope.gridSearch && !!scope.gridSearch[inputName];
        }

        function configInput(newVal, oldVal) {
          $log.debug("Dropdown Ready: " + oldVal + " --> " + newVal);
          if (newVal === true) {
            if ($scope.gridSearch && $scope.gridSearch[inputName]) {
              var control = $scope.gridSearch[inputName];
              control.$formatters.push(function (rawValue) {
                return self.getDropdownValue(field, self.fields[field].dropdown, rawValue);
              });

              control.$parsers.push(function (displayValue) {
                var tRange = self.fields[field].dropdown.translatedrange;
                var val = Object.keys(tRange)
                  .filter(function (k) {
                    return [k, tRange[k].value].includes[displayValue];
                  })
                  .reduce(function (acc, k) {
                    if (!acc) {
                      acc = k;
                    }
                    return acc;
                  }, null);
                return val ? val : "";
              });
              inputBacking.modelinit[field](); //dereg watcher for field
              inputBacking.readyDefered[field].resolve();
            }
          }
        }
      }

      function watchFormDirty() {

        return $scope.$watch(getDirty, clearShowresult);

        function getDirty(scope) {
          return scope.gridSearch ? scope.gridSearch.$dirty : null;
        }

        function clearShowresult(newVal, oldVal) {
          $log.debug("gridSearch dirty: " + oldVal + " --> " + newVal);
          if (newVal === oldVal) {
            return; // quick exit on initialization
          }
          if (newVal === true) {
            if (self.params.showresult) {
              delete self.params.showresult;
            }
            if ($uiRouter.globals.params.showresult) {
              delete $uiRouter.globals.params.showresult;
            }
          }
        }
      }

      function watchParams() {

        return $scope.$watch(getParams, updateParams);

        function getParams() {
          return $uiRouter.globals.params;
        }

        function updateParams(newVal, oldVal) {
          //we RELY on the initialization invocation so no old == new check
          $log.debug("updateParams... GLOBAL before: search: " + angular.toJson($uiRouter.globals.params));

          self.params = Object.keys(self.fields).reduce(function (p, k) {
            if (self.fields[k] && self.fields[k].type === 'date') {
              p[k] = gridService.parseDateLike(p[k]);
            }
            return p;
          }, angular.copy($uiRouter.globals.params));

          if (self.params.hasOwnProperty('#')) {
            delete self.params['#'];
          }

          $log.debug("updateParams... LOCAL before: search: " + angular.toJson(self.params));
        }

      }

      function submitDownload() {
        submitSearch(true);
      }

      function submitSearch(download) {
        if (gridService.getSearching()) {
          //only start new search or download if none is running
          return;
        }
        var myParams = {};
        Object.keys(self.params).forEach(function (k) {
          if (self.fields[k]) {
            if (self.fields[k].type === 'date') {
              var d = self.params[k];
              if (d instanceof Date) {
                d = d.valueOf();
              }
              // DATETIME FORMAT DEPENDENCY
              myParams[k] = moment.tz(d, 'Europe/Berlin').format("DD.MM.YYYY");
            } else if (self.fields[k].type === 'number') {
              if (self.fields[k].interval) {
                myParams[k] = [0, 1].reduce(function (acc, idx) {
                  var v = self.params[k] ? asInt(self.params[k][idx]) : null;
                  if (v) {
                    acc.push(v);
                  }
                  return acc;
                }, []);
              } else {
                myParams[k] = asInt(self.params[k]);
              }
            } else {
              myParams[k] = self.params[k];
            }

          } else {
            myParams[k] = self.params[k];
          }
        });

        if (download) {

          //build query and load data
          var query = gridConfigService.getQuery(myParams);
          gridService.setSearching(true);
          gridDataService.exportData(query, myParams.datasource).then(
            function success(resp) {
              // remove searching flag and stop search animation
              gridService.setSearching(false);
            },
            function error(resp) {
              // remove searching flag and stop search animation
              gridService.setSearching(false);
            }
          );


        } else {
          //always show result
          myParams['showresult'] = true;
          //always trigger search
          myParams['searchtrigger'] = new Date().valueOf();
          var nextState = gridConfigService.getStatemap()[myParams['datasource']];
          $uiRouter.stateService.go(nextState, myParams);
        }


        function asInt(v) {
          var n = parseInt(v);
          // if n = NaN then n != n ... an example of an "alternative equality" ?? ;-)
          return n === n ? n : null;
        }
      }

    }
  ])
;
