angular.module('fiskal').component('alphanumDialog', function () {

  //definition returned at end of function
  var alphanumDefinition = {
    require: {},
    template: ' \
        <div style="position: relative;"> \
          <label for="{{$ctrl.name}}" ng-class="{\'ff-dialog-readonly\': $ctrl.field.readonly}">\
            <translate>{{$ctrl.field.label}}</translate> \
          </label> \
        </div> \
        <div style="position: relative;"> \
          <span ng-if="$ctrl.field.required && !$ctrl.field.readonly" class="ff-dialog-required">*</span> \
          <input \
            type="text" \
            name="{{$ctrl.name}}" \
            ng-class="{\'ff-dialog-readonly\': $ctrl.field.readonly}" \
            ng-required="$ctrl.field.required" \
            ng-readonly="$ctrl.field.readonly" \
            ng-pattern="$ctrl.getPattern()" \
            ng-model="$ctrl.field.value" \
            ng-blur="$ctrl.updateModel()" \
            placeholder=" " > \
        </div> \
      ',
    bindings: {
      form: "<",
      name: "<",
      field: "<"
    },
    controller: ['$scope', '$element', '$attrs', '$transclude', '$injector', ctrl]
  };

  //controller
  function ctrl($scope, $element, $attrs, $transclude, $injector) {
    var self = this; // return self;

    var $log = $injector.get('$log');
    var gridService = $injector.get('gridService');

    var deregs = [];

    var patterns = {
      integer: '(?:\\d+)?',
      float: '(?:\\d+(?:,|(?:,\\d+))?)?',
      text: '(?:.+)?'
  };

    self.$onInit = function () {
      $log.debug("$onInit: " + self.name);

      //update form input displayField
      self.field.displayValue = gridService.formatAsDisplayValue(self.field.value, self.field.coldef);

    };

    self.$postLink = function () {
      $log.debug("$postLink: " + self.name);
      var control = self.form[self.name];
      if (control) {
        control.$formatters.push(function(value){
          return gridService.formatAsDisplayValue(value, self.field.coldef);
        });
        control.$parsers.push(function(displayValue){
          return gridService.parseGridDisplayedValue(displayValue, self.field.coldef) || "";
        });
      }
    };

    self.$onDestroy = function () {
      deregs.forEach(function (f) {
        f();
      });
    };

    self.getPattern = function() {
      return patterns[self.field.type];
    };

    self.updateModel = function() {
      var control = self.form[self.name];
      control.$commitViewValue();
    };
  }

  return alphanumDefinition;

}());
