"use strict";

//Angular module REFERENCE .module(<name>) WITHOUT dependency list
angular.module('fiskal').controller('gridSubgridController',
  ['$log',
    function ($log) {
      var self = this; // return self;
      //BEGIN CONSTRUCTOR
      //closure vars: private variables
      var _subgridOptions = null;
      var _rows = null;
      var _style = {};


      // api: public methods and fields
      self.setSubgridOptions = setSubgridOptions;
      self.tableStyle = _style;


      //END CONSTRUCTOR
      return self;

      //internal functions: private methods
      function setSubgridOptions(subgridOptions) {
        _subgridOptions = subgridOptions;
        _rows = _subgridOptions.data;

        //show between 4 and 10 rows in subgrid canvas
        _subgridOptions.minRowsToShow = Math.max(Math.min(_rows.length + 1, 5), 2);

        //tablewidth: maxwidth cols + 15px horiz scrollbar
        _style.width = _subgridOptions.columnDefs
            .filter(function (c) {
              return c.visible;
            })
            .reduce(function (acc, c) {
              acc += c.maxWidth;
              return acc;
            }, 0) + 15;

        return _subgridOptions;
      }

    }
  ]);
